import "./style.scss";

import { Button, Col, Form, Layout, Row, Select, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { Form1 } from "../RequestFormExport/FormKU1";
import { Form2 } from "../RequestFormExport/FormKU3";
import { Form3 } from "../RequestFormExport/FormGeneral";
import { Form4 } from "../RequestFormExport/FormMove";
import { Form5 } from "../RequestFormExport/FormOff";
import { Form6 } from "../RequestFormExport/FormLeave";
import { Form7 } from "../RequestFormExport/FormBreak";
import { LeftOutlined } from "@ant-design/icons";
import RequesForm1 from "./RequestFromType/RequesForm1";
import RequesForm2 from "./RequestFromType/RequesForm2";
import RequesForm3 from "./RequestFromType/RequesForm3";
import RequesForm4 from "./RequestFromType/RequesForm4";
import RequesForm5 from "./RequestFromType/RequesForm5";
import RequesForm6 from "./RequestFromType/RequesForm6";
import RequesForm7 from "./RequestFromType/RequesForm7";
import RequesForm8 from "./RequestFromType/RequesForm8";
import { clearProfileImg } from "../Profile/ProfilePageSlice";
import { fetchDraftForm } from "./RequestFormMainDraftFormSlice";
import { fetchDraftFormUpload } from "./RequestFormMainDraftUploadFileFormSlice";
import { fetchPreviewData } from "./FormPreviewSlice";
import { fetchRequestList } from "./RequestFormMainSlice";
import { fetchSubmitForm } from "./RequestFormMainSubmitFormSlice";
import { getImageDimensions } from "../../util/GetImageDimensions";
import moment from "moment";
import { setPreviewDataNull } from "./FormPreviewSlice";
import { useNavigate } from "react-router-dom";
import { validateUserProfile } from "../../util/requiredInfo";
// Form PDF

const { Content } = Layout;
const { Option } = Select;

export default function RequestForm() {
  const [form] = Form.useForm();
  const [formType, setFormType] = useState(null);
  const [signatureImage, setSignatureImage] = useState(null);
  const [isFormSubmited, setIsFormSubmited] = useState(false);
  const [errorUpload, setErrorUpload] = useState(null);
  const [isEditState, setIsEditState] = useState(false);
  const requestList = useSelector((state) => state.requestList.requestList);
  const previewData = useSelector((state) => state.previewFormData.previewData);
  const userData = useSelector((state) => state.userDetail.userDetail);
  const [buttonState, setButtonState] = useState("");
  const isCompleteDraftSave = useSelector(
    (state) => state.draftForm.isCompleted
  );
  const isCompleteSubmit = useSelector((state) => state.submitForm.isLoading);

  const isCompletDraft = useSelector((state) => state.draftForm.isLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formDraft = async () => {
    setErrorUpload(null);
    const formValue = form.getFieldsValue();
    const formRequestId = formValue.reqTopic.split("_")[0];
    const signatureDimension = await getImageDimensions(formValue.signature);
    const imgWidth = signatureDimension.width;
    const imgHeight = signatureDimension.height;
    const calWidth = imgWidth / (imgHeight / 5);
    const calHeight = 5;

    let formPatern = {
      UserId: userData.Id,
      FormTypeId: Number(formRequestId),
      Data: formValue,
      DataUser: userData,
      Signature: formValue.signature,
      SignatureDimension: {
        width: Number(calWidth.toFixed(2)),
        height: calHeight,
      },
      Action: "DRAFT",
      SignatureEmail: userData.Email,
      Comment: "",
      ApprovalStepAction: formValue.signature ? "APPROVED" : "",
    };
    delete formPatern.Data.signature;
    delete formPatern.Data.form_atth_doc;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("Id");
    if (code) {
      const saveFirstDraftresponse = await dispatch(
        fetchDraftForm({ id: code, formData: formPatern })
      );
      const formValueForSubmit = form.getFieldsValue();
      if (
        formValueForSubmit.form_atth_doc &&
        formValueForSubmit?.form_atth_doc.fileList &&
        formValueForSubmit?.form_atth_doc.fileList.length > 0
      ) {
        try {
          const uploadResponse = await dispatch(
            fetchDraftFormUpload({
              id: saveFirstDraftresponse.payload.Id,
              filename: formValueForSubmit?.form_atth_doc.file?.name,
              file:
                formValueForSubmit?.form_atth_doc.fileList.length > 0
                  ? formValueForSubmit?.form_atth_doc.fileList[0]?.originFileObj
                  : "",
            })
          );
          console.log("uploadResponse", uploadResponse);
          if (uploadResponse.payload === "filelength") {
            setErrorUpload("ชื่อไฟล์ไม่ควรยาวเกิน 30 ตัวอักษร");
            return;
          }

          if (uploadResponse.payload === "filesize") {
            setErrorUpload("ไฟล์ไม่ควรใหญ่เกิน 5 MB");
            return;
          }
        } catch (error) {
          console.log("formDraft uplaod error", error);
        }
      }
      dispatch(fetchPreviewData(code));
      message.success("บันทึกฉบับร่าง สำเร็จ");
    } else {
      const saveFirstDraftresponse = await dispatch(
        fetchSubmitForm(formPatern)
      );
      const formValueForSubmit = form.getFieldsValue();
      if (
        formValueForSubmit.form_atth_doc &&
        formValueForSubmit.form_atth_doc.file
      ) {
        try {
          const uploadResponse = await dispatch(
            fetchDraftFormUpload({
              id: saveFirstDraftresponse.payload.Id,
              filename: formValueForSubmit?.form_atth_doc.file?.name,
              file:
                formValueForSubmit?.form_atth_doc.fileList.length > 0
                  ? formValueForSubmit?.form_atth_doc.fileList[0]?.originFileObj
                  : "",
            })
          );
          console.log("uploadResponse", uploadResponse);
          if (uploadResponse.payload === "filelength") {
            setErrorUpload("ชื่อไฟล์ไม่ควรยาวเกิน 30 ตัวอักษร");
            return;
          }

          if (uploadResponse.payload === "filesize") {
            setErrorUpload("ไฟล์ไม่ควรใหญ่เกิน 5 MB");
            return;
          }
        } catch (error) {
          console.log("formDraft2 uplaod error", error);
        }
      }

      message.success("Save first draft completed ");
      navigate("/list-document-student");
    }
  };

  const formSubmit = async (values) => {
    setErrorUpload(null);
    const formRequestId = values.reqTopic.split("_")[0];
    const signatureDimension = await getImageDimensions(values.signature);
    const imgWidth = signatureDimension.width;
    const imgHeight = signatureDimension.height;
    const calWidth = imgWidth / (imgHeight / 5);
    const calHeight = 5;

    const formPatern = {
      UserId: userData.Id,
      FormTypeId: Number(formRequestId),
      Data: values,
      DataUser: userData,
      Signature: values.signature,
      SignatureDimension: {
        width: Number(calWidth.toFixed(2)),
        height: calHeight,
      },
      Action: "SUBMITTED",
      SignatureEmail: userData.Email,
      Comment: "",
      ApprovalStepAction: values.signature ? "APPROVED" : "",
    };
    delete formPatern.Data.form_atth_doc;
    delete formPatern.Data.signature;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("Id");
    if (code) {
      const saveFirstDraftresponse = await dispatch(
        fetchDraftForm({ id: code, formData: formPatern })
      );
      const formValueForSubmit = form.getFieldsValue();

      if (
        formValueForSubmit.form_atth_doc &&
        formValueForSubmit.form_atth_doc.file
      ) {
        try {
          const uploadResponse = await dispatch(
            fetchDraftFormUpload({
              id: saveFirstDraftresponse.payload.Id,
              filename: formValueForSubmit?.form_atth_doc.file?.name,
              file:
                formValueForSubmit?.form_atth_doc.fileList.length > 0
                  ? formValueForSubmit?.form_atth_doc.fileList[0]?.originFileObj
                  : "",
            })
          );
          console.log("uploadResponse", uploadResponse);
          if (uploadResponse.payload === "filelength") {
            setErrorUpload("ชื่อไฟล์ไม่ควรยาวเกิน 30 ตัวอักษร");
            return;
          }

          if (uploadResponse.payload === "filesize") {
            setErrorUpload("ไฟล์ไม่ควรใหญ่เกิน 5 MB");
            return;
          }
        } catch (error) {
          console.log("formSubmit uplaod error", error);
        }
      }

      if (isCompleteDraftSave) {
        message.success("Submit form completed");
      }
    } else {
      const saveFirstDraftresponse = await dispatch(
        fetchSubmitForm(formPatern)
      );
      const formValueForSubmit = form.getFieldsValue();
      if (
        formValueForSubmit.form_atth_doc &&
        formValueForSubmit.form_atth_doc.file
      ) {
        try {
          const uploadResponse = await dispatch(
            fetchDraftFormUpload({
              id: saveFirstDraftresponse.payload.Id,
              filename: formValueForSubmit?.form_atth_doc.file?.name,
              file:
                formValueForSubmit?.form_atth_doc.fileList.length > 0
                  ? formValueForSubmit?.form_atth_doc.fileList[0]?.originFileObj
                  : "",
            })
          );
          console.log("uploadResponse", uploadResponse);
          if (uploadResponse.payload === "filelength") {
            setErrorUpload("ชื่อไฟล์ไม่ควรยาวเกิน 50 ตัวอักษร");
            return;
          }

          if (uploadResponse.payload === "filesize") {
            setErrorUpload("ไฟล์ไม่ควรใหญ่เกิน 5 MB");
            return;
          }
        } catch (error) {
          console.log("formSubmit uplaod error", error);
        }
      }
      message.success("Submit form completed");
    }

    if (!isCompleteSubmit) {
      navigate("/list-document-student");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const checked = validateUserProfile(userData);
    if (!checked) navigate("/edit-profile");
  }, [userData]);

  useEffect(() => {
    dispatch(fetchRequestList());
    dispatch(clearProfileImg());
  }, [dispatch]);

  // Preview
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("Id");
    if (code) {
      dispatch(fetchPreviewData(code));
      setIsEditState(true);
    } else {
      return;
    }
  }, [dispatch]);

  useEffect(() => {
    if (previewData) {
      if (previewData.Action === "SUBMITTED") {
        setIsFormSubmited(true);
        message.error("ฟอร์มนี้ทำการ SUBMIT ไปแล้วไม่สามารถแก้ไขได้");
      }
      setFormType(previewData.FormType.Code);
      setSignatureImage(previewData.ApprovalStep[0].SignatureImage);

      const setDataDrafttoForm = {
        reqTopic: previewData.Data.reqTopic,
        form_atth_doc: previewData.FormFile[0]?.Name,
        ...previewData.Data,
      };

      const setDataDrafttoFormDateRange = {
        reqTopic: previewData.Data.reqTopic,
        ...previewData.Data,
      };

      if (previewData.ApprovalStep[0]) {
        form.setFieldsValue({
          signature: previewData.ApprovalStep[0].SignatureImage,
        });
      }

      if (setDataDrafttoForm.form_date_range) {
        delete setDataDrafttoForm.form_date_range;
        form.setFieldsValue({
          form_date_range: [
            moment(setDataDrafttoFormDateRange.form_date_range[0]),
            moment(setDataDrafttoFormDateRange.form_date_range[1]),
          ],
        });
        form.setFieldsValue(setDataDrafttoForm);
      } else if (!setDataDrafttoForm.form_date_range) {
        form.setFieldsValue(setDataDrafttoForm);
      }
    }
  }, [previewData, form]);

  //Destroy form data
  useEffect(() => {
    return () => {
      dispatch(setPreviewDataNull());
    };
  }, [dispatch]);

  return (
    <Content className="site-layout-background">
      <div className="navigate-top">
        <div className="left">
          <Button
            onClick={() => {
              navigate("/main");
            }}
            icon={<LeftOutlined />}
          />
          <h1 className="top-txt-content">ยื่นคำร้อง</h1>
        </div>
        <div className="right">
          <Button onClick={() => navigate("/main")}>ไปเมนูหลัก</Button>
        </div>
      </div>
      <Form
        disabled={isFormSubmited}
        className="wrap-form-request"
        form={form}
        layout="vertical"
        name="basic"
        onFinish={formSubmit}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{
          form_subjects: [""],
          form_wd_subjects: [""],
          form_en_subjects: [""],
          form_subjects_leave: [""],
          form_purpose_1: "ภาคต้น",
          form_purpose: "1",
        }}
      >
        <Row>
          <Col md={12} sm={24} xs={24}>
            <Form.Item
              label="ประเภทคำร้อง"
              className="select-form-type"
              name="reqTopic"
              rules={[
                {
                  required: true,
                  message: "กรุณาเลือกคำร้อง",
                },
              ]}
            >
              {requestList ? (
                <Select
                  disabled={isEditState}
                  showSearch
                  placeholder="เลือกคำร้อง"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  onChange={(e) => {
                    const str = e.split("_")[1];
                    setFormType(str);
                  }}
                >
                  <Option key={98} value={`2_KU1`}>
                    {
                      `แบบฟอร์ม KU1` /*   {x.Code === 'KU1' || x.Code === 'KU3' ? <><span style={{ color: 'white' }}>-----</span> {`แบบฟอร์ม ${x.Code}`}</> : }*/
                    }
                  </Option>
                  <Option key={99} value={`3_KU3`}>
                    {`แบบฟอร์ม KU3`}
                  </Option>
                  {requestList.map((x, i) =>
                    x.Code !== "KU1" && x.Code !== "KU3" ? (
                      <Option key={i} value={`${x.Id}_${x.Code}`}>
                        {x.Name}
                      </Option>
                    ) : null
                  )}
                </Select>
              ) : (
                <Spin>
                  <Select placeholder="เลือกคำร้อง"></Select>
                </Spin>
              )}
            </Form.Item>
          </Col>
        </Row>
        {formType === "KU1" ? (
          <RequesForm1 form={form} signatureImage={signatureImage} />
        ) : formType === "KU3" ? (
          <RequesForm2 form={form} signatureImage={signatureImage} />
        ) : formType === "GEN" ? (
          <RequesForm3 form={form} signatureImage={signatureImage} />
        ) : formType === "MOVE" ? (
          <RequesForm4 form={form} signatureImage={signatureImage} />
        ) : formType === "OFF" ? (
          <RequesForm5 form={form} signatureImage={signatureImage} />
        ) : formType === "LEAVE" ? (
          <RequesForm6 form={form} signatureImage={signatureImage} />
        ) : formType === "BREAK" ? (
          <RequesForm7 form={form} signatureImage={signatureImage} />
        ) : formType === "SCOL" ? (
          <RequesForm8 form={form} signatureImage={signatureImage} />
        ) : (
          <p
            style={{
              color: "red",
              fontSize: "30px",
              margin: "60px",
              width: "80%",
              align: "center",
            }}
          >
            <p>
              <Emoji unified="1f4e3" size="25" />

              <Emoji unified="1f4e3" size="25" />
              <span style={{ color: "red", fontSize: "32px", margin: "0 5px" }}>
                ประชาสัมพันธ์
              </span>
              <Emoji unified="1f4e3" size="25" />
              <Emoji unified="1f4e3" size="25" />
            </p>

            <div type="remark">
              <ul>
                <li>
                  นิสิตสามารถตรวจสอบกำหนดการยื่นเอกสารคำร้องต่างๆ
                  <br />
                  ได้ผ่านปฎิทินการศึกษา มก. ประจำปีการศึกษา 2567{" "}
                  <a target="_blank" href="https://registrar.ku.ac.th/wp-content/uploads/2023/12/%E0%B8%9B%E0%B8%8E%E0%B8%B4%E0%B8%97%E0%B8%B4%E0%B8%99%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%A8%E0%B8%B6%E0%B8%81%E0%B8%A9%E0%B8%B2-2567-%E0%B8%9B%E0%B8%A3%E0%B8%B4%E0%B8%8D%E0%B8%8D%E0%B8%B2%E0%B8%95%E0%B8%A3%E0%B8%B5-%E0%B8%9C%E0%B9%88%E0%B8%B2%E0%B8%99-%E0%B8%81%E0%B8%9A%E0%B8%A1-1.pdf">
                    {" "}
                    คลิก{" "}
                  </a>
                </li>
              </ul>
            </div>
          </p>
        )}

        {errorUpload && (
          <div
            style={{ color: "red", marginBottom: "8px" }}
          >{`บันทึกไม่สำเร็จ: ${errorUpload}`}</div>
        )}
        {formType ? (
          <div className="wrap-button-action">
            <Form.Item>
              <Button
                loading={previewData === null ? true : false}
                onClick={() => {
                  if (formType === "KU1") {
                    Form1(previewData);
                  } else if (formType === "KU3") {
                    Form2(previewData);
                  } else if (formType === "GEN") {
                    Form3(previewData);
                  } else if (formType === "MOVE") {
                    Form4(previewData);
                  } else if (formType === "OFF") {
                    Form5(previewData);
                  } else if (formType === "LEAVE") {
                    Form6(previewData);
                  } else if (formType === "BREAK") {
                    Form7(previewData);
                  } else {
                    return;
                  }
                }}
                disabled={previewData ? false : true}
                className="sent-request"
              >
                ตัวอย่างก่อนพิมพ์
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                onClick={() => {
                  setButtonState("draft");
                  formDraft();
                }}
                className="save-draft"
                loading={
                  (isCompletDraft || isCompleteSubmit) &&
                  buttonState === "draft"
                }
              >
                บันทึกฉบับร่าง
              </Button>
            </Form.Item>
            {formType === "KU3" || formType === "KU1" ? (
              <></>
            ) : (
              <Form.Item>
                <Button
                  loading={
                    isCompleteSubmit ||
                    (isCompletDraft && buttonState === "submit")
                  }
                  htmlType="submit"
                  className="sent-request"
                  onClick={() => {
                    setButtonState("submit");
                  }}
                >
                  ส่งคำร้อง
                </Button>
              </Form.Item>
            )}
          </div>
        ) : null}
      </Form>
    </Content>
  );
}

import React, { useRef, useState } from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Button, Form, Radio, Row, Col, Upload } from "antd";
import SignatureCanvas from "react-signature-canvas";
import "./style.scss";

export default function RequesForm8(form) {
  const sigCanvas = useRef({});
  const [filename1, setFilename1] = useState(null);
  const [filename2, setFilename2] = useState(null);
  const [filename3, setFilename3] = useState(null);
  const [filename4, setFilename4] = useState(null);
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <div className="wrap-form wrap-form-1">
      <p className="form-7-text-explain">
        ข้าพเจ้ามีความประสงค์สมัครขอรับทุนการศึกษาประเภท
      </p>
      <Row>
        <Col md={18} sm={24} xs={24}>
          <Form.Item
            name="form8_radio"
            rules={[
              {
                required: true,
                message: "กรุณาเลือกประเภท",
              },
            ]}
          >
            <Radio.Group className="radio-group-form-8">
              <Radio value={1}>
                <div className="wrap-form-8-choice">
                  <p className="top-text">ประเภท "พัฒนาศักยภาพการเรียน"</p>
                  <p className="second-text">
                    โดยข้าพเจ้ามีคุณสมบัติครบตามประกาศคณะเกษตร ดังนี้
                  </p>
                  <ul>
                    <li>
                      1. ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ
                    </li>
                    <li>
                      1. ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ
                    </li>
                    <li>
                      1. ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ
                    </li>
                  </ul>
                </div>
              </Radio>
              <Radio value={2}>
                <div className="wrap-form-8-choice">
                  <p className="top-text">ประเภท "เรียนดี"</p>
                  <p className="second-text">
                    โดยข้าพเจ้ามีคุณสมบัติครบตามประกาศคณะเกษตร ดังนี้
                  </p>
                  <ul>
                    <li>
                      1. ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ
                    </li>
                    <li>
                      1. ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ
                    </li>
                    <li>
                      1. ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ ตัวอย่างข้อความ ตัวอย่างข้อความ
                      ตัวอย่างข้อความ
                    </li>
                  </ul>
                </div>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={18} sm={24} xs={24}>
          <div className="wrap-from-7-upload-section form-8-upload-section">
            <p>
              ทั้งนี้ ข้าพเจ้าได้แนบเอกสารการสมัครขอรับทุนมาพร้อมแล้ว ดังนี้
            </p>
            <div className="upload-section">
              <p style={{ marginBottom: 0 }}>
                1. สำเนาบัตรประจำตัวนิสิต (กรุณาอัพโหลดเอกสารแนบ)
              </p>
              <div className="wrap-upload-component">
                <div className="file-name-section">{filename1}</div>
                <div className="upload-btn">
                  <Form.Item
                    name="form8_atth_doc_1"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาอัพโหลดเอกสารแนบ",
                      },
                    ]}
                  >
                    <Upload
                      accept="image/png, image/jpeg, .pdf"
                      onChange={(e) => {
                        setFilename1(e.file.name);
                      }}
                      maxCount={1}
                      customRequest={dummyRequest}
                    >
                      <Button icon={<CloudUploadOutlined />} />
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="upload-section">
              <p style={{ marginBottom: 0 }}>
                2. ใบรายงานผลการเรียน (กรุณาอัพโหลดเอกสารแนบ)
              </p>
              <div className="wrap-upload-component">
                <div className="file-name-section">{filename2}</div>
                <div className="upload-btn">
                  <Form.Item
                    name="form8_atth_doc_2"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาอัพโหลดเอกสารแนบ",
                      },
                    ]}
                  >
                    <Upload
                      accept="image/png, image/jpeg, .pdf"
                      onChange={(e) => {
                        setFilename2(e.file.name);
                      }}
                      maxCount={1}
                      customRequest={dummyRequest}
                    >
                      <Button icon={<CloudUploadOutlined />} />
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="upload-section">
              <p style={{ marginBottom: 0 }}>
                3. คำร้องและความเห็นของอาจารย์ที่ปรึกษา (กรุณาอัพโหลดเอกสารแนบ)
              </p>
              <div className="wrap-upload-component">
                <div className="file-name-section">{filename3}</div>
                <div className="upload-btn">
                  <Form.Item
                    name="form8_atth_doc_3"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาอัพโหลดเอกสารแนบ",
                      },
                    ]}
                  >
                    <Upload
                      accept="image/png, image/jpeg, .pdf"
                      onChange={(e) => {
                        setFilename3(e.file.name);
                      }}
                      maxCount={1}
                      customRequest={dummyRequest}
                    >
                      <Button icon={<CloudUploadOutlined />} />
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="upload-section">
              <p style={{ marginBottom: 0 }}>
                4. คำร้องและความเห็นของผู้ปกครอง (กรุณาอัพโหลดเอกสารแนบ)
              </p>
              <div className="wrap-upload-component">
                <div className="file-name-section">{filename4}</div>
                <div className="upload-btn">
                  <Form.Item
                    name="form8_atth_doc_4"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาอัพโหลดเอกสารแนบ",
                      },
                    ]}
                  >
                    <Upload
                      accept="image/png, image/jpeg, .pdf"
                      onChange={(e) => {
                        setFilename4(e.file.name);
                      }}
                      maxCount={1}
                      customRequest={dummyRequest}
                    >
                      <Button icon={<CloudUploadOutlined />} />
                    </Upload>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
            rules={[
              {
                message: "กรุณาระบุ กรุณาลงนามกำกับเอกสาร",
                required: true,
              },
            ]}
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

import * as ApiEndPoint from "../../shared/api/ApiEndPoint";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";

const initialState = {
  isLoading: false,
  isCompleted: null,
  error: null,
};

export const fetchSubmitForm = createAsyncThunk(
  "request/submit",
  async (dataForm, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post(ApiEndPoint.SUBMITTED, dataForm);
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const submitForm = createSlice({
  name: "submitForm",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSubmitForm.pending]: (state) => {
      state.isLoading = true;
      state.isCompleted = null;
    },
    [fetchSubmitForm.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isCompleted = action.payload;
    },
    [fetchSubmitForm.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default submitForm.reducer;

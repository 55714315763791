import { Button, Pagination, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import './style.scss'
import ICONVIEW from '../../assets/image/logo/view-icon.svg'
import ICONSETTING from '../../assets/image/logo/icon-setting.svg'
import ModalViewStatusReportDocument from './Modal/ModalViewStatusReportDocument'
import ApiClient from '../../shared/api/ApiClient'
import { parseJwt } from '../../util/TokenUtill'
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import localeTh from "dayjs/locale/th";
export default function ListDocumentTeacher(approved) {
    const [visibleModalViewStatus, setVisibleModalViewStatus] = useState(false);
    const [dataTransection, setDataTransection] = useState();
    const [dataTable, setDataTable] = useState();
    const [isLoadingDataTable, setIsLoadingDataTable] = useState(false);
    const [transectionId, setTransectionId] = useState(null);
    const [pageSize, setPageSize] = useState(null);
    const navigate = useNavigate()

    const columns = [
        {
            title: 'สถานะการจัดการ',
            dataIndex: 'Action',
            key: 'Action',
            width: "12%",
            render: (text, record, index) => {
                return <p key={index} className={`txt-list-teacher-status ${text}`}> {text === 'PENDING' ? "ยังไม่ได้ลงนาม" : ""}{text === 'APPROVED' ? "ลงนามเรียบร้อยแล้ว" : ""}{text === 'REJECTED' ? "เอกสารไม่ผ่านการพิจารณา" : ""}</p>;
            }
        },
        {
            title: 'นิสิตผู้ยื่นคำร้อง',
            dataIndex: 'applicantStudent',
            key: 'applicantStudent',
            width: "15%",
            render: (text, record, index) => {
                return record.FormTransaction.DataUser.FirstnameTh ? record.FormTransaction.DataUser.ThaiPrename + ` ` + record.FormTransaction.DataUser.FirstnameTh + ` ` + record.FormTransaction.DataUser.LastnameTh : "-";
            }
        },
        {
            title: 'ชื่อคำร้อง',
            dataIndex: 'reportName',
            key: 'reportName',
            width: "17%",
            render: (text, record, index) => {
                return record.ApprovalTemplate.FormType ? record.ApprovalTemplate.FormType.Name : "-";
            }
        },
        {
            title: 'วันที่ยื่นคำร้อง',
            dataIndex: 'DatetimeCreated',
            key: 'DatetimeCreated',
            width: "17%",
            render: (text, record, index) => {

                return text ? dayjs(text).locale(localeTh)
                    .format("DD MMM BBBB เวลา HH:mm") : "";
            }
        },
        {
            title: 'ตำแหน่งที่รับผิดชอบ',
            dataIndex: 'currentStatus',
            key: 'currentStatus',
            width: "10%",
            render: (text, record, index) => {
                return record.ApprovalTemplate.Name ? record.ApprovalTemplate.Name === 'แอดมิน' ? 'ผู้ดูแลระบบ' : `${record.ApprovalTemplate.Name}` : "-";
            }

        },
        {
            title: "หมายเหตุ",
            dataIndex: "currentStatus",
            key: "currentStatus",
            width: "10%",
            render: (text, record, index) => {
               // console.log('record', record)
                let rejected = record?.FormTransaction?.ApprovalStep?.filter(
                    (x) => x.Action === "REJECTED"
                );
                if (rejected?.length > 0) {
                    return rejected[0]?.Notation
                }
                return "-"
            }
        },
        {
            title: '',
            dataIndex: 'address',
            key: 'address',
            width: "20%",
            render: (text, record, index) => {

                return (
                    <div key={"group-btn" + index} className='wrap-group-button-list-document-teacher'>
                        <Button onClick={() => {
                            setDataTransection(record)
                            setVisibleModalViewStatus(true)
                            setTransectionId(record.FormTransactionId)
                        }}
                        ><img src={ICONVIEW} alt="icon-view"></img> </Button>
                        {approved.approved ? <></> :
                            <Button onClick={() => {
                                navigate(`/approve`, { state: record })
                            }} disabled={record.statusDocument === "success" ? true : false}><img src={ICONSETTING} alt="icon-edit"></img>พิจารณาเอกสารและลงนาม</Button>
                        } </div>
                )
            }
        },
    ];

    useEffect(() => {
        getFormTransectionList(approved, 1)
    }, []);

    async function getFormTransectionList(approved, page) {
        setIsLoadingDataTable(true)
        try {
            let userDetail = parseJwt(sessionStorage.getItem('access_token'))
            let url = `${process.env.REACT_APP_API_HOST}/users/${userDetail.user_id}/${approved.approved ? 'approved' : 'need-approve'}?take=5&page=${page}`
            let result = await (await ApiClient.get(url)).data
            setPageSize(result.LastPageNumber)
            setDataTable(result.Items)
            setIsLoadingDataTable(false)
        } catch (error) {
            console.log('error', error)
            setIsLoadingDataTable(false)
        }
    }

    return (
        <div className='wrap-list-document-teacher-page'>
            <div className='wrap-list-document-teacher-page'>
                <div className="navigate-top">
                    <div className="left">
                        <Button icon={<LeftOutlined />} onClick={() => navigate("/main")} />
                        <h1 className="top-txt-content">เอกสารคำร้อง{`${approved.approved? 'ที่ลงนามแล้ว': 'ที่ยังไม่ได้ลงนาม'}`}</h1>
                    </div>
                    <div className="right">
                        <Button onClick={() => navigate("/main")}>ไปเมนูหลัก</Button>
                    </div>
                </div>
                <div className='wrap-search-section-document-teacher'>
                </div>
                <div className='wrap-content-table-document-teacher'>
                    <Table rowKey={(x) => `${x.Id}`} key={"table-key"} dataSource={dataTable} columns={columns} loading={isLoadingDataTable} pagination={false} />
                    <br />
                    {
                        pageSize ? <Pagination onChange={x => {
                            console.log('x', x)
                            getFormTransectionList(approved, x)
                        }} defaultCurrent={1} total={pageSize} pageSize={1} /> : ""
                    }
                </div>
            </div>
            <ModalViewStatusReportDocument transectionId={transectionId} visible={visibleModalViewStatus} closeModal={() => setVisibleModalViewStatus(false)} dataProps={dataTransection} />
        </div>
    )

}
import jsPDF from "jspdf";
import "jspdf-autotable";
import sarabunFontMedium from "../../assets/fonts/sarabun/Sarabun-Medium.ttf";
import sarabunRegular from "../../assets/fonts/sarabun/Sarabun-Regular.ttf";
import sarabunLight from "../../assets/fonts/sarabun/Sarabun-Light.ttf";
import sarabunFont from "../../assets/fonts/sarabun/THSarabunNew.ttf";
import kuLogo from "../../assets/image/logo/ku_logo.jpeg";
import checkMark from "../../assets/image/checkmark.png";
import { thaitext } from "../../util/Thaitext";
import { thaiDate } from "../../util/DateObject";
import { dateSignature } from "../../util/DateSignature";
import { valueTransform, fullNameTransform, GetApprovalByPositionName } from "../../util/ValueTransform";
export const Form6 = (props) => {
  const doc = new jsPDF({
    orientation: "p",
    format: "a4",
  });

  doc.addFont(sarabunFont, "Sarabun", "normal");
  doc.addFont(sarabunFontMedium, "Sarabun-Medium", "normal");
  doc.addFont(sarabunRegular, "Sarabun-Regular", "normal");
  doc.addFont(sarabunLight, "Sarabun-Light", "normal");

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(8);
  // Start Top Square
  doc.rect(157, 5, 43, 25, "S");
  thaitext(doc, "สำนักบริหารการศึกษา", 165, 10);
  thaitext(doc, "เลขที่  ........................................", 160, 17);
  thaitext(doc, "วันที่  .........................................", 160, 24);
  thaitext(doc, "วันที่  ........../.................../.............", 159, 37);
  const created = thaiDate(props.DatetimeCreated);
  thaitext(
    doc,
    created.day + '          ' + created.month + '    ' + created.year,
    167,
    36
  );
  doc.setFontSize(8);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Date                        DD/MM/YY", 159, 42);
  // End Top Square

  doc.setFont("Sarabun-Medium");
  doc.setFontSize(15);
  doc.addImage(kuLogo, "JPEG", 10, 5, 25, 25, 0, "NONE", 0);
  doc.text("มหาวิทยาลัยเกษตรศาสตร์", 40, 15);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(12);
  doc.text("ใบขอลาออก/ Resignation Form", 40, 23);
  doc.setFontSize(10);
  doc.text("เรียน.................................................", 10, 36);
  thaitext(doc, fullNameTransform(props, 'advisor'), 25, 35);
  doc.setFont("Sarabun-Light");
  doc.text("To           (อาจารย์ที่ปรึกษา/Advisor)", 10, 41);
  doc.setFont("Sarabun-Regular");
  thaitext(
    doc,
    "ชื่อนิสิต ............................................................................................................................................................ตัวบรรจง",
    23,
    50
  );

  thaitext(doc, fullNameTransform(props), 80, 49);
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  doc.text("Student’s name (Mr./Mrs./Miss)", 23, 55);
  doc.text("(Print name)", 183, 55);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  doc.text("เลขประจำตัวนิสิต", 10, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[0]), 42, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[1]), 48, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[2]), 54, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[3]), 60, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[4]), 66, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[5]), 72, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[6]), 78, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[7]), 84, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[8]), 90, 63);
  thaitext(doc, valueTransform(props.DataUser.Code[9]), 96, 63);
  doc.rect(40, 58, 6, 8, "S");
  doc.rect(46, 58, 6, 8, "S");
  doc.rect(52, 58, 6, 8, "S");
  doc.rect(58, 58, 6, 8, "S");
  doc.rect(64, 58, 6, 8, "S");
  doc.rect(70, 58, 6, 8, "S");
  doc.rect(76, 58, 6, 8, "S");
  doc.rect(82, 58, 6, 8, "S");
  doc.rect(88, 58, 6, 8, "S");
  doc.rect(94, 58, 6, 8, "S");
  thaitext(
    doc,
    "นิสิตชั้นปีที่..............คณะ................................................................",
    105,
    63
  );
  thaitext(doc, valueTransform(props.DataUser.AcademicYear).toString(), 125, 62);
  thaitext(doc, valueTransform(props.DataUser.Faculty), 145, 62);
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(doc, "Student ID Number", 10, 68);
  thaitext(doc, "Academic Level", 102, 68);
  thaitext(doc, "Faculty", 131, 68);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "สาขาวิชา..................................................................................หมายเลขโทรศัพท์........................................................................................",
    10,
    76
  );
  thaitext(doc, valueTransform(props.DataUser.Major && props.DataUser.Major !== "" ? props.DataUser.Major : props.DataUser.Department), 30, 75);
  thaitext(doc, valueTransform(props.DataUser.Phonenumber), 128, 75);
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(
    doc,
    "Major Field                                                                                           Phone number",
    10,
    80
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);

  thaitext(
    doc,
    "มีความประสงค์ขอลาออก เนื่องจาก..............................................................................................................................................................",
    10,
    87
  );

  thaitext(doc, valueTransform(props.Data.form_reason), 64, 86);
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(doc, "Reason(s) for Resignation", 10, 91);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "จึงขอลาออกตั้งแต่บัดนี้เป็นต้นไป และข้าพเจ้าไม่มีหนี้สินค้างชำระ",
    10,
    99
  );
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(
    doc,
    "This resignation is effective immediately. I have no outstanding debt.",
    10,
    103
  );
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(10);
  thaitext(
    doc,
    "ลงนามนิสิต/ผู้ดำเนินการแทน.......................................................",
    110,
    108
  );
  doc.addImage(
    valueTransform(props.ApprovalStep[0]?.SignatureImage),
    "JPEG",
    160,
    102,
    valueTransform(props.ApprovalStep[0]?.SignatureDimension?.width),
    5,
    "signature-step-1",
    "NONE",
    0
  );
  doc.setFont("Sarabun-Light");
  doc.setFontSize(9);
  thaitext(doc, "Student/Person Requesting Signature", 110, 112);

  doc.rect(10, 118, 95.25, 43);
  doc.rect(105.25, 118, 95.25, 43);
  doc.rect(10, 161, 95.25, 43);
  doc.rect(105.25, 161, 95.25, 43);
  doc.rect(10, 204, 95.25, 43);
  doc.rect(105.25, 204, 95.25, 43);
  doc.rect(10, 247, 95.25, 43);
  doc.rect(105.25, 247, 95.25, 43);

  // text in boxes

  // Start Box 1
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "1. คำยินยอมของผู้ปกครอง", 15, 123);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Guardian’s Consent", 19, 127);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "อนุมัติ", 30, 133);
  doc.rect(25, 130.5, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Approve", 39, 133);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่อนุมัติ", 30, 139);
  if (GetApprovalByPositionName(props, 'ผู้ปกครอง')?.Action === 'APPROVED') {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      128,
      5,
      5,
      "signature-step-checkmark1",
      "NONE",
      0
    );
  } else if (GetApprovalByPositionName(props, 'ผู้ปกครอง')?.Action === 'REJECTED') {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      134,
      5,
      5,
      "signature-step-checkmark1",
      "NONE",
      0
    );
  }
  doc.rect(25, 136, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Denied", 42, 139);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    144
  );

  doc.addImage(
    valueTransform(GetApprovalByPositionName(props, 'ผู้ปกครอง')?.SignatureImage),
    "JPEG",
    65,
    138,
    valueTransform(GetApprovalByPositionName(props, 'ผู้ปกครอง')?.SignatureDimension?.width),
    5,
    "signature-step-2",
    "NONE",
    0
  );

  thaitext(doc, "(.................................................)", 55, 149);
  thaitext(doc, valueTransform(GetApprovalByPositionName(props, 'ผู้ปกครอง')?.ForDev?.topic), 60, 148);
  thaitext(doc, "........./.........../..............", 65, 154);
  thaitext(doc, dateSignature(GetApprovalByPositionName(props, 'ผู้ปกครอง')?.DatetimeUpdated ?? GetApprovalByPositionName(props, 'ผู้ปกครอง')?.DatetimeCreated), 65, 153);
  thaitext(doc, "ผู้ปกครอง", 65, 159);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Guardian", 79, 159);

  // End Box 1

  // Start Box 2
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "2. เรียน หัวหน้าภาควิชา", 110, 123);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To Head of department", 113, 127);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "อนุมัติ", 125, 133);
  doc.rect(120, 130.5, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Approve", 134, 133);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่อนุมัติ", 125, 139);

  if (GetApprovalByPositionName(props, 'อาจารย์ที่ปรึกษา')?.Action === 'APPROVED') {
    doc.addImage(
      checkMark,
      "PNG",
      120,
      128,
      5,
      5,
      "signature-step-checkmark2",
      "NONE",
      0
    );
  } else if (GetApprovalByPositionName(props, 'อาจารย์ที่ปรึกษา')?.Action === 'REJECTED') {
    doc.addImage(
      checkMark,
      "PNG",
      120,
      134,
      5,
      5,
      "signature-step-checkmark2",
      "NONE",
      0
    );
  }

  doc.rect(120, 136, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Denied", 137, 139);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    144
  );
  doc.addImage(
    valueTransform(GetApprovalByPositionName(props, 'อาจารย์ที่ปรึกษา')?.SignatureImage),
    "JPEG",
    155,
    138,
    5,
    5,
    "signature-teacher-1",
    "NONE",
    0
  );

  thaitext(
    doc,
    "(.................................................)",
    150,
    149
  );


  thaitext(doc, fullNameTransform(props, 'advisor'), 154, 148);
  thaitext(doc, "........./.........../..............", 160, 154);
  thaitext(doc, dateSignature(GetApprovalByPositionName(props, 'อาจารย์ที่ปรึกษา')?.DatetimeUpdated ?? GetApprovalByPositionName(props, 'อาจารย์ที่ปรึกษา')?.DatetimeCreated), 160, 153);
  thaitext(doc, "อาจารย์ที่ปรึกษา", 155, 159);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Advisor", 178, 159);
  // End Box 2

  // Start box 3
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "3. เรียน คณบดี", 15, 166);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To Dean", 19, 170);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่มีหนี้สินค้างชำระกับคณะ", 30, 176);
  doc.rect(25, 173.5, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "No debt with faculty", 67, 176);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "มีหนี้สินค้างชำระ", 30, 182);
  doc.rect(25, 179, 3, 3);

  if (GetApprovalByPositionName(props, 'หัวหน้าภาควิชา')?.Action === 'APPROVED') {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      171,
      5,
      5,
      "signature-step-checkmark11",
      "NONE",
      0
    );
  } else if (GetApprovalByPositionName(props, 'หัวหน้าภาควิชา')?.Action === 'REJECTED') {
    doc.addImage(
      checkMark,
      "PNG",
      25,
      177,
      5,
      5,
      "signature-step-checkmark11",
      "NONE",
      0
    );
  }
  doc.setFont("Sarabun-Light");
  thaitext(doc, "In debt", 54, 182);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    187
  );

  doc.addImage(
    valueTransform(GetApprovalByPositionName(props, 'หัวหน้าภาควิชา')?.SignatureImage),
    "JPEG",
    70,
    181,
    valueTransform(GetApprovalByPositionName(props, 'หัวหน้าภาควิชา')?.SignatureDimension?.width),
    5,
    "signature-step-3",
    "NONE",
    0
  );
  thaitext(doc, valueTransform(GetApprovalByPositionName(props, 'หัวหน้าภาควิชา')?.ForDev?.Department?.Chief), 58, 191);
  thaitext(doc, "(.................................................)", 55, 192);
  thaitext(doc, "........./.........../..............", 65, 197);
  thaitext(doc, dateSignature(GetApprovalByPositionName(props, 'หัวหน้าภาควิชา')?.DatetimeUpdated ?? GetApprovalByPositionName(props, 'หัวหน้าภาควิชา')?.DatetimeCreated), 65, 196);
  thaitext(doc, "หัวหน้าภาควิชา", 50, 202);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Head of Department", 71, 202);
  // End Box 3

  // Start Box 4
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, `4. เรียน คณบดี`, 110, 166);//`4. ${valueTransform(GetApprovalByPositionName(props, "คณบดี")?.ForDev?.AgendaForDean)}`
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To Dean", 114, 170);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่มีหนี้สินค้างชำระกับคณะ", 125, 176);
  doc.rect(120, 173.5, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "No debt with faculty", 162, 176);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "มีหนี้สินค้างชำระ", 125, 182);

  if (GetApprovalByPositionName(props, 'เลขานุการคณะ')?.Action === 'APPROVED') {
    doc.addImage(
      checkMark,
      "PNG",
      120,
      171,
      5,
      5,
      "signature-step-checkmark4",
      "NONE",
      0
    );
  } else if (GetApprovalByPositionName(props, 'เลขานุการคณะ')?.Action === 'REJECTED') {
    doc.addImage(
      checkMark,
      "PNG",
      120,
      177,
      5,
      5,
      "signature-step-checkmark4",
      "NONE",
      0
    );
  }
  doc.rect(120, 179, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "In debt", 149, 182);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    187
  );

  doc.addImage(
    valueTransform(GetApprovalByPositionName(props, 'เลขานุการคณะ')?.SignatureImage),
    "JPEG",
    165,
    181,
    valueTransform(GetApprovalByPositionName(props, 'เลขานุการคณะ')?.SignatureDimension?.width),
    5,
    "signature-step-6",
    "NONE",
    0
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    192
  );
  thaitext(doc, valueTransform(GetApprovalByPositionName(props, 'เลขานุการคณะ')?.ApprovalTemplate?.FullName), 155, 191);
  thaitext(doc, "........./.........../..............", 158, 197);
  thaitext(doc, dateSignature(valueTransform(GetApprovalByPositionName(props, 'เลขานุการคณะ')?.DatetimeUpdated  ?? GetApprovalByPositionName(props, 'เลขานุการคณะ')?.DatetimeCreated)), 158, 196);

  thaitext(doc, "เลขานุการคณะ", 145, 202);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Head of Secretariat", 166, 202);
  // End Box 4

  // Start Box 5
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "5. เรียน คณบดี", 15, 209);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To Dean", 19, 213);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่มีหนี้สินค้างชำระกับฝ่ายกิจการนิสิต", 17, 219);
  doc.rect(12, 216, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "No debt with Student Affairs", 66, 219);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "มีหนี้สินค้างชำระ", 17, 225);
  doc.rect(12, 222, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "In debt", 39, 225);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    230
  );
  thaitext(doc, "(.................................................)", 55, 235);
  thaitext(doc, "........./.........../..............", 65, 240);
  thaitext(doc, "เจ้าหน้าที่ฝ่ายกิจการนิสิต", 38, 245);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Student Affairs Officer", 71, 245);
  // End Box 5

  // Start Box 6
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "6. เรียน คณบดี", 110, 209);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "To Dean", 114, 213);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่มีหนี้สินค้างชำระกับสำนักหอสมุด", 112, 219);
  doc.rect(107, 216, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "No debt with KU Library", 158, 219);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "มีหนี้สินค้างชำระ", 112, 225);
  doc.rect(107, 222, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "In debt", 134, 225);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    230
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    235
  );
  thaitext(doc, "........./.........../..............", 160, 240);
  thaitext(doc, "เจ้าหน้าที่สำนักหอสมุดกลาง", 140, 245);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Library Officer", 177, 245);
  // End Box 6

  // Start Box 7
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "7. คำพิจารณาของคณบดีเจ้าสังกัด", 15, 252);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Dean’s decision", 19, 256);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "อนุมัติ", 30, 262);
  doc.rect(25, 259.5, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Approve", 39, 262);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "ไม่อนุมัติ", 30, 268);
  doc.rect(25, 265, 3, 3);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Denied", 42, 268);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    30,
    273
  );
  thaitext(doc, "(.................................................)", 55, 278);
  thaitext(doc, "........./.........../..............", 65, 283);
  thaitext(doc, "คณบดี", 68, 288);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "Dean", 77, 288);
  // End Box 7

  // Start Box 8
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "8. เรียน ผู้อำนวยการสำนักบริหารการศึกษา", 110, 252);
  doc.setFont("Sarabun-Light");
  thaitext(
    doc,
    "To Director of Office of Educational Administration",
    114,
    256
  );

  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(doc, "เพื่อโปรดดำเนินการ", 142, 262);
  doc.setFont("Sarabun-Light");
  thaitext(doc, "to be processed", 144, 268);
  doc.setFont("Sarabun-Regular");
  doc.setFontSize(9);
  thaitext(
    doc,
    "ลงนาม/Signature...........................................................",
    125,
    273
  );
  thaitext(
    doc,
    "(.................................................)",
    150,
    278
  );
  thaitext(doc, "........./.........../..............", 160, 283);
  doc.output("dataurlnewwindow");
  // doc.save("form1.pdf");
};

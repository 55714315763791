import "./style.scss";

import { Button, Col, DatePicker, Form, Input, Radio, Row,Upload } from "antd";
import React, { useRef ,useState} from "react";
import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";

const { RangePicker } = DatePicker;

const { TextArea } = Input;

export default function RequesForm5(form) {
  const [isClearSignature, setIsClearSignature] = React.useState(false);
  const sigCanvas = useRef({});
  const [filename, setFilename] = useState(null);
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
    setIsClearSignature(true);
  };
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  return (
    <div className="wrap-form wrap-form-1">
      <Row gutter={20}>
        <Col md={24} sm={24} xs={24}>
          <Row gutter={20}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                name="form_topic"
                className="wrap-form-topic-radio"
                label="เรื่อง"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุ เรื่อง",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>ขอลาป่วย</Radio>
                  <Radio value={2}>ขอลากิจ</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={24} sm={24} xs={24}>
          <Form.Item
            name="form_date_range"
            label="วันที่ลา"
            rules={[
              {
                required: true,
                message: "กรุณาระบุ วันที่ลา",
              },
            ]}
          >
            <RangePicker
              format={"DD-MM-YYYY"}
              onChange={(e) => {
                if (e) {
                  var now = e[0]; //todays date
                  var end = e[1]; // another date
                  var duration = moment.duration(now.diff(end));
                  var days = duration.asDays();
                  form.form.setFieldsValue({
                    form_date: days * -1 + 1,
                  });
                }
              }}
            />
          </Form.Item>
          <Form.Item name="form_date" label="จำนวนวันที่ลา">
            <Input readOnly addonAfter={"วัน"} style={{ width: 100 }} />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name="form_reason"
            label="ความประสงค์ (ระบุความประสงค์และเหตุผล)"
            rules={[
              {
                required: true,
                message: "กรุณาระบุ ความประสงค์",
              },
              {
                max: 1000,
                message: "ตัวอักษรไม่ควรเกิน 1000 ตัวอักษร",
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={200}
              style={{ height: 180 }}
              placeholder="ความประสงค์ (ระบุความประสงค์และเหตุผล)"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={24} xs={24}>
          <div className="wrap-from-7-upload-section upload-form-1">
            <p>{`เอกสารแนบลาป่วย : ใบรับรองแพทย์ (ถ้ามี)`}</p>
            <p>
              เอกสารแนบลากิจ <span style={{ marginLeft: "0.6rem" }}>{""}</span>:
              เอกสารประกอบการลา (ถ้ามี)
            </p>
            <div className="wrap-upload-component">
              <div className="file-name-section">{filename}</div>
              <div className="upload-btn">
                <Form.Item name="form_atth_doc">
                  <Upload
                    accept="image/png, image/jpeg, .pdf"
                    onChange={(e) => {
                      setFilename(e.file.name);
                    }}
                    maxCount={1}
                    customRequest={dummyRequest}
                  >
                    <Button icon={<CloudUploadOutlined />} />
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <span>นามสกุลไฟล์ที่รองรับ pdf. png. jpeg.</span>
            <div style={{ color: "red" }}>
              * กรณีลาเกิน 15 วัน ต้องแนบใบรับรองแพทย์
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
            rules={[
              {
                message: "กรุณาระบุ กรุณาลงนามกำกับเอกสาร",
                required: true,
              },
            ]}
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              {form.signatureImage && !isClearSignature ? (
                <div className="wrap-image-signature">
                  <img src={form.signatureImage} alt="signature" />
                </div>
              ) : null}
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
        <Col md={24}>
          <p>
            หมายเหตุ: หากลาไม่เกิน 15 วันทำการ
            ระบบจะส่งให้อาจารย์ที่ปรึกษาอนุมัติเพียงท่านเดียว
          </p>
        </Col>
      </Row>
    </div>
  );
}

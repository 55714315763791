import "./style.scss";

/* eslint-disable jsx-a11y/alt-text */
import { Button, Col, Modal, Row, Spin, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ApiClient from "../../../shared/api/ApiClient";
import { Form1 } from "../../RequestFormExport/FormKU1";
import { Form2 } from "../../RequestFormExport/FormKU3";
import { Form3 } from "../../RequestFormExport/FormGeneral";
import { Form4 } from "../../RequestFormExport/FormMove";
import { Form5 } from "../../RequestFormExport/FormOff";
import { Form6 } from "../../RequestFormExport/FormLeave";
import { Form7 } from "../../RequestFormExport/FormBreak";
import ICONCHECKED from "../../../assets/image/logo/icon-checked.svg";
import ICONPENDING from "../../../assets/image/logo/icon-pending.svg";
import CANCELPENDING from "../../../assets/image/logo/icon-cancel.svg";
import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import localeTh from "dayjs/locale/th";
import { setPreviewDataNull } from "../../RequestForm/FormPreviewSlice";

dayjs.extend(customParseFormat).extend(buddhistEra);

const { Step } = Steps;

export default function ModalViewStatusReportDocument(props) {
  const [transectionDetail, setTransectionDetail] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.visible) {
      callServiceGetTransectionDetail(props.transectionId);
    }
  }, [props]);

  async function callServiceGetTransectionDetail(id) {
    try {
      if (id) {
        let result = await (
          await ApiClient.get(
            `${process.env.REACT_APP_API_HOST}/formTransactions/${id}/detail`
          )
        ).data;
        setTransectionDetail(result);
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  function getSignDateTime(transectionDetail) {
    let item = {};
    for (
      let index = transectionDetail?.ApprovalStep.length - 1;
      index > -1;
      index--
    ) {
      const element = transectionDetail?.ApprovalStep[index];
      if (
        element.Action === "APPROVED" ||
        element.ApprovalTemplate.StepNumber === 0
      ) {
        item = element;
        break;
      }
    }
    return item.DatetimeUpdated
      ? dayjs(new Date(item.DatetimeUpdated))
          .locale(localeTh)
          .format("DD MMM BBBB เวลา HH:mm")
      : dayjs(new Date(item.DatetimeCreated))
          .locale(localeTh)
          .format("DD MMM BBBB เวลา HH:mm");
  }

  useEffect(() => {
    return () => {
      dispatch(setPreviewDataNull());
    };
  }, [dispatch]);

  return (
    <Modal
      className="warp-modal-view-status-document-student"
      destroyOnClose={true}
      onCancel={() => {
        props.closeModal();
        setTransectionDetail(null);
      }}
      visible={props?.visible}
      width={750}
      footer={<div></div>}
    >
      {transectionDetail ? (
        <Row gutter={[16, 16]} style={{ marginTop: 10 }}>
          <Col md={14}>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">ชื่อคำร้อง</p>
              </Col>
              <Col md={14}>
                {(() => {
                  if (transectionDetail.FormType.Id === 4) {
                    return (
                      <p className="wrap-txt-detail">
                        {transectionDetail.Data.form_topic === 1
                          ? "ขอย้ายสาขาวิชา"
                          : "ขอย้ายแขนงวิชา"}
                      </p>
                    );
                  } else if (transectionDetail.FormType.Id === 5) {
                    return (
                      <p className="wrap-txt-detail">
                        {transectionDetail.Data.form_topic === 1
                          ? "ลาป่วย"
                          : "ลากิจ"}
                      </p>
                    );
                  } else {
                    return (
                      <p className="wrap-txt-detail">
                        {transectionDetail.Data.form_topic ??
                          transectionDetail.FormType.Name}
                      </p>
                    );
                  }

                  return null;
                })()}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">วันที่ยื่น</p>
              </Col>
              <Col md={14}>
                <p className="wrap-txt-detail">
                  {console.log("transectionDetail", transectionDetail)}
                  {dayjs(transectionDetail.DatetimeCreated)
                    .locale(localeTh)
                    .format("DD MMM BBBB เวลา HH:mm")}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">สถานะล่าสุด</p>
              </Col>
              <Col md={14}>
                <p className="wrap-txt-detail">
                  {getSignDateTime(transectionDetail)}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={8}>
                <p className="wrap-txt-section">เอกสารแนบ</p>
              </Col>
              <Col md={14}>
                {transectionDetail.FormFile &&
                transectionDetail.FormFile.length !== 0 ? (
                  <a
                    className="form-file-modal-student"
                    target={"_blank"}
                    href={`${process.env.REACT_APP_API_HOST}${
                      transectionDetail.FormFile[
                        transectionDetail.FormFile.length - 1
                      ].Url
                    }`}
                    rel="noreferrer"
                  >
                    {
                      transectionDetail.FormFile[
                        transectionDetail.FormFile.length - 1
                      ].Name
                    }
                  </a>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={24}>
                <Button
                  onClick={async () => {
                    if (transectionDetail.FormType.Code === "KU1") {
                      Form1(transectionDetail);
                    } else if (transectionDetail.FormType.Code === "KU3") {
                      Form2(transectionDetail);
                    } else if (transectionDetail.FormType.Code === "GEN") {
                      Form3(transectionDetail);
                    } else if (transectionDetail.FormType.Code === "MOVE") {
                      Form4(transectionDetail);
                    } else if (transectionDetail.FormType.Code === "OFF") {
                      Form5(transectionDetail);
                    } else if (transectionDetail.FormType.Code === "LEAVE") {
                      Form6(transectionDetail);
                    } else if (transectionDetail.FormType.Code === "BREAK") {
                      Form7(transectionDetail);
                    } else {
                      return;
                    }
                  }}
                  className="modal-view-status-btn-preview-document"
                >
                  ตัวอย่างก่อนพิมพ์คำร้อง
                </Button>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col md={24}>
                <div style={{ marginTop: "30px", color: "red" }}>
                  <p>
                    ช่องทางการส่งเอกสารคำร้องไปยังสำนักบริหารการศึกษา{" "}
                    <a
                      href="https://registrar.ku.ac.th/request"
                      target="_blank"
                    >
                      https://registrar.ku.ac.th/request
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={10}>
            <Steps direction="vertical" current={null}>
              {transectionDetail.ApprovalStep.map((x) => {
                return x.ApprovalTemplate.Code !== "officeAdmin" &&
                  x.Action != "NONE" ? (
                  <Step
                    title={(() => {
                      if (x.ApprovalTemplate.Name == "อาจารย์ประจำวิชา") {
                        return `${x.ApprovalTemplate.Name}`;
                      } else if (
                        x.ApprovalTemplate.Name ==
                          "หัวหน้าภาคฯ / ประธานบริหาร" &&
                        x.ApprovalTemplate.FormTypeId !== 5
                      ) {
                        return "หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ";
                      } else if (
                        x.ApprovalTemplate.FormTypeId == 5 &&
                        transectionDetail.Data.form_date < 15 &&
                        x.ApprovalTemplate.StepNumber > 1
                      ) {
                        return "";
                      } else if (
                        x.ApprovalTemplate.Name == "หัวหน้าภาคฯ / ประธานบริหาร"
                      ) {
                        return "หัวหน้าภาคฯ / ประธานบริหารหลักสูตรฯ";
                      } else if (
                        x.ApprovalTemplate.Name == "ประธานบริหารหลักสูตรใหม่" &&
                        x.ApprovalTemplate.FormTypeId == 4
                      ) {
                        return "ประธานบริหารหลักสูตรฯใหม่";
                      } else if (
                        x.ApprovalTemplate.Name == "ประธานบริหารหลักสูตรฯ" &&
                        x.ApprovalTemplate.FormTypeId == 4
                      ) {
                        return "ประธานบริหารหลักสูตรฯเดิม";
                      } else {
                        return x.ApprovalTemplate.Name;
                      }

                      return null;
                    })()}
                    icon={
                      x.Action === "APPROVED" &&
                      (x.ApprovalTemplate.FormTypeId === 3 ||
                        x.ApprovalTemplate.FormTypeId === 2) ? (
                        <img
                          src={ICONCHECKED}
                          width={32}
                          height={32}
                          alt="icon-check"
                        ></img>
                      ) : x.Action === "PENDING" &&
                        x.ApprovalTemplate.FormTypeId === 5 &&
                        transectionDetail.Data.form_date < 15 &&
                        x.ApprovalTemplate.StepNumber > 1 ? (
                        <></>
                      ) : transectionDetail.Action === "DRAFT" ? (
                        <img
                          src={ICONPENDING}
                          width={32}
                          height={32}
                          alt="icon-pending"
                        ></img>
                      ) : x.Action === "APPROVED" ? (
                        <img
                          src={ICONCHECKED}
                          width={32}
                          height={32}
                          alt="icon-check"
                        ></img>
                      ) : x.Action === "REJECTED" ? (
                        <img
                          src={CANCELPENDING}
                          width={32}
                          height={32}
                          alt="icon-pending"
                        ></img>
                      ) : (x.ApprovalTemplate.FormTypeId == 2 ||
                          x.ApprovalTemplate.FormTypeId == 3) &&
                        x.ApprovalTemplate.StepNumber == 0 ? (
                        <img
                          src={ICONCHECKED}
                          width={32}
                          height={32}
                          alt="icon-check"
                        ></img>
                      ) : (
                        <img
                          src={ICONPENDING}
                          width={32}
                          height={32}
                          alt="icon-pending"
                        ></img>
                      )
                    }
                  />
                ) : (
                  <></>
                );
              })}
            </Steps>
          </Col>
        </Row>
      ) : (
        <Spin></Spin>
      )}
    </Modal>
  );
}

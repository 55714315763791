import React from "react";
import { Form1 } from "../RequestFormExport/FormKU1";
import { Form2 } from "../RequestFormExport/FormKU3";
import { Form6 } from "../RequestFormExport/FormLeave";
import { Form7 } from "../RequestFormExport/FormBreak";
import { Form3 } from "../RequestFormExport/FormGeneral";
import { Form4 } from "../RequestFormExport/FormMove";
import { Form5 } from "../RequestFormExport/FormOff";

export default function FormExportMain() {
  return (
    <div style={{ padding: "130px" }}>
      <button
        onClick={() => {
          Form1();
        }}
      >
        form1
      </button>
      <button
        onClick={() => {
          Form2();
        }}
      >
        form2
      </button>
      <button
        onClick={() => {
          Form3();
        }}
      >
        form3
      </button>
      <button
        onClick={() => {
          Form4();
        }}
      >
        form4
      </button>
      <button
        onClick={() => {
          Form5();
        }}
      >
        form5
      </button>
      <button
        onClick={() => {
          Form6();
        }}
      >
        form6
      </button>
      <button
        onClick={() => {
          Form7();
        }}
      >
        form7
      </button>
      <button>form8</button>
    </div>
  );
}

import MainPageSlice from "../../features/Main/MainPageSlice";
import PreviewFormData from "../../features/RequestForm/FormPreviewSlice";
import ProfilePageSlice from "../../features/Profile/ProfilePageSlice";
import RequestFormMainDraftFormSlice from "../../features/RequestForm/RequestFormMainDraftFormSlice";
import RequestFormMainDraftUploadFileFormSlice from "../../features/RequestForm/RequestFormMainDraftUploadFileFormSlice";
import RequestFormMainSlice from "../../features/RequestForm/RequestFormMainSlice";
import RequestFormMainSubmitFormSlice from "../../features/RequestForm/RequestFormMainSubmitFormSlice";
import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

export default combineReducers({
  router: connectRouter(history),
  requestList: RequestFormMainSlice,
  masterData: MainPageSlice,
  submitForm: RequestFormMainSubmitFormSlice,
  draftForm: RequestFormMainDraftFormSlice,
  draftFormUpload: RequestFormMainDraftUploadFileFormSlice,
  previewFormData: PreviewFormData,
  userDetail: ProfilePageSlice,
});

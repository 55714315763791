import "./style.scss";

import { Button, Col, Form, Input, Row } from "antd";
import React, { useRef } from "react";

import SignatureCanvas from "react-signature-canvas";

const { TextArea } = Input;

export default function RequesForm6(form) {
  const [isClearSignature, setIsClearSignature] = React.useState(false);
  const sigCanvas = useRef({});
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
    setIsClearSignature(true);
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  return (
    <div className="wrap-form wrap-form-1">
      <Row gutter={20}>
        <Col md={24} sm={24} xs={24}>
          <Row gutter={20}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                extra={
                  <div style={{ paddingTop: "16px" }}>
                    จึงขอลาออกตั้งแต่บัดนี้เป็นต้นไป
                    และข้าพเจ้าไม่มีหนี้สินค้างชำระ
                  </div>
                }
                name="form_reason"
                label="มีความประสงค์ขอลาออก เนื่องจาก"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุ หน่วยกิตรวม",
                  },
                  {
                    max: 85,
                    message: "ตัวอักษรไม่ควรเกิน 85 ตัวอักษร",
                  },
                ]}
              >
                <TextArea
                  showCount
                  maxLength={85}
                  style={{ height: 180 }}
                  placeholder="มีความประสงค์ขอลาออก เนื่องจาก"
                />
              </Form.Item>
            </Col>

            <Col md={12}>
              <div
                style={{
                  paddingLeft: "20px",
                  paddingRight: "100px",
                  paddingTop: "30px",
                  color: "red",
                }}
              >
                <h3 style={{ fontWeight: "bold", color: "red" }}>หมายเหตุ</h3>
                <div className="remark">
                  <ul>
                    <li>
                      <span>
                        เมื่อเสร็จสิ้นกระบวนการในระบบ
                        (หลังจากที่เลขานุการคณะลงนาม)
                        ขอให้นิสิตจัดพิมพ์เอกสารเพื่อนำส่งให้หน่วยงานภายนอกลงนามต่อไป
                      </span>
                      <ul>
                        <li>เจ้าหน้าที่ฝ่ายกิจการนิสิต (กองพัฒนานิสิต)</li>
                        <li>เจ้าหน้าที่สำนักหอสมุดกลาง</li>
                      </ul>
                    </li>
                    <li>
                      หลังจากหน่วยงานภายนอกลงนามแล้ว
                      นิสิตจะต้องนำส่งเอกสารที่งานบริการการศึกษาคณะเกษตร
                      เพื่อเสนอคณบดีคณะเกษตร ลงนามต่อไป
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
            rules={[
              {
                message: "กรุณาระบุ กรุณาลงนามกำกับเอกสาร",
                required: true,
              },
            ]}
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              {form.signatureImage && !isClearSignature ? (
                <div className="wrap-image-signature">
                  <img src={form.signatureImage} alt="signature" />
                </div>
              ) : null}
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeTh from "dayjs/locale/th";
dayjs.extend(customParseFormat).extend(buddhistEra);

export const thaiDate = (date) => {
  if (date) {
    return {
      day: dayjs(date).locale(localeTh).format("DD"),
      month: dayjs(date).locale(localeTh).format("MMMM"),
      year: dayjs(date).locale(localeTh).format("BBBB"),
    };
  } else {
    return {
      day: "",
      month: "",
      year: "",
    };
  }
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ApiEndPoint from "../../shared/api/ApiEndPoint";
import ApiClient from "../../shared/api/ApiClient";

const initialState = {
  isLoading: false,
  masterData: null,
  error: null,
};

export const fetchMasterData = createAsyncThunk(
  "getMasterData",
  async (path, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(ApiEndPoint.GET_MASTER_DATA);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const getMasterData = createSlice({
  name: "getMasterData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMasterData.pending]: (state) => {
      state.isLoading = true;
      state.masterData = null;
    },
    [fetchMasterData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.masterData = action.payload;
    },

    [fetchMasterData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default getMasterData.reducer;

import React, { useEffect, useState } from "react";
import ApiClient from "../../shared/api/ApiClient";
import { useNavigate } from "react-router-dom";
import { validateUserProfile } from "../../util/requiredInfo";
import { notification, Spin } from "antd";
import { parseJwt } from "../../util/TokenUtill";
import * as ApiEndPoint from "../../shared/api/ApiEndPoint";
export default function LandingPage() {
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const access_token = sessionStorage.getItem("access_token");
    if (code) {
      console.log("code", code);
      authenticationService(code);
    } else if (access_token) {
      sessionStorage.setItem("refresh_main", "false");
      navigate("/main");
    } else {
      navigate("login");
    }
  }, []);

  async function authenticationService(code) {
    setisLoading(true);
    try {
      let payload = { authorization_code: code };
      const result = await (
        await ApiClient.post(
          `${process.env.REACT_APP_API_HOST}/auth/login`,
          payload
        )
      ).data;

      if (result.access_token) {
        sessionStorage.setItem("access_token", result.access_token);
        sessionStorage.setItem("refresh_token", result.refresh_token);
        sessionStorage.setItem("refresh_main", "true");

        const resultToken = parseJwt(result.access_token);
        const userData = await ApiClient.get(
          `${process.env.REACT_APP_API_HOST}${ApiEndPoint.USER}/${resultToken?.user_id}`,
          {
            headers: {
              Authorization: `Bearer ${result.access_token}`,
            },
          }
        );
        const checked = await validateUserProfile(userData.data.access_token);
        if (checked) {
          navigate("/main");
        } else {
          sessionStorage.setItem("flx-edit-profile", "true");
          navigate("/edit-profile");
        }
      }
      setisLoading(false);
    } catch (error) {
      console.log("error", error);
      notification.error({
        message: `Error`,
        description: `${error?.response?.data?.message}`,
      });
      navigate(`/login`);
      setisLoading(false);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isLoading ? <Spin></Spin> : ""}
    </div>
  );
}



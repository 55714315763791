import "./style.scss";

import React, { useEffect, useState } from "react";
import { Spin, notification } from "antd";
import { clearUserDetail, fetchUserDetail } from "../Profile/ProfilePageSlice";
import { useDispatch, useSelector } from "react-redux";

import ICONADMIN from "../../assets/image/logo/icon-admin.svg";
import ICONCHART from "../../assets/image/logo/icon-chart.svg";
import ICONFORM from "../../assets/image/logo/icon-form.svg";
import ICONLIST from "../../assets/image/logo/icon-list.svg";
import ICONOPENFOLDER from "../../assets/image/logo/icon-open-folder.svg";
import ICONPENCIL from "../../assets/image/logo/pencil-172.svg";
import ICONSIGN from "../../assets/image/logo/icon-sign.svg";
import ICONUSER from "../../assets/image/logo/logo-user-green.svg";
import LOGOAERICULTURE from "../../assets/image/logo/logomain.png";
import { fetchMasterData } from "./MainPageSlice";
import { useNavigate } from "react-router-dom";

export default function MainPage() {
  const navigate = useNavigate();
  const dispact = useDispatch();
  const selector = useSelector((state) => state.userDetail);
  const [userDetail, setUserDetail] = useState(null);

  useEffect(() => {
    setUserDetail(null);
    let refreshMain = sessionStorage.getItem("refresh_main");
    if (refreshMain === "true") {
      console.log("reload");
      window.location.reload();
      sessionStorage.setItem("refresh_main", "false");
    } else {
      dispact(fetchMasterData());
      dispact(fetchUserDetail());
    }
  }, []);

  useEffect(() => {
    if (selector.userDetail !== null) {
      setUserDetail(selector.userDetail);
    }
  }, [selector]);
  return (
    <div>
      <div className="wrap-component-main-page">
        {userDetail && sessionStorage.getItem("refresh_main") === "false" ? (
          userDetail.Role === "STUDENT" ? (
            <div className="wrap-content-main-page">
              <div className="wrap-header-main-page">
                <img
                  style={{ width: "190px" }}
                  src={LOGOAERICULTURE}
                  alt="logo-agriculture"
                ></img>
                <div className="header-text">
                  <p>ระบบสารสนเทศสำหรับนิสิต</p>
                </div>
                {/* <p>คณะเกษตร มหาวิทยาลัยเกษตรศาสตร์</p> */}
              </div>

              <div className="wrap-list-main-menu">
                <div
                  className="wrap-box-main-menu"
                  onClick={() => navigate("/profile")}
                >
                  <p>ข้อมูลส่วนบุคคล</p>
                  <div className="wrap-img-list-main-menu">
                    <img
                      className="icon-main-absolute icon-size"
                      src={ICONUSER}
                      alt="icon-user"
                    ></img>
                  </div>
                </div>
                <div
                  className="wrap-box-main-menu"
                  onClick={() => navigate("/request-form")}
                >
                  <p>ยื่นคำร้อง</p>
                  <div className="wrap-img-list-main-menu">
                    <img
                      className="icon-main-absolute icon-size"
                      src={ICONFORM}
                      alt="icon-form"
                    ></img>
                  </div>
                </div>
                <div
                  className="wrap-box-main-menu"
                  onClick={() => navigate("/list-document-student")}
                >
                  <p>เอกสารคำร้องทั้งหมด</p>
                  <div className="wrap-img-list-main-menu">
                    <img
                      className="icon-main-absolute icon-size"
                      src={ICONOPENFOLDER}
                      alt="icon-open-folder"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          ) : userDetail.Role === "PROFESSOR" ||
            userDetail.Role === "OFFICER" ? (
            <div className="wrap-content-main-page">
              <div className="wrap-header-main-page">
                <img
                  style={{ width: "190px" }}
                  src={LOGOAERICULTURE}
                  alt="logo-agriculture"
                ></img>
                <div className="header-text">
                  <p>
                    ระบบสารสนเทศสำหรับ
                    {userDetail.Role === "PROFESSOR"
                      ? "อาจารย์"
                      : "เจ้าหน้าที่"}
                  </p>
                </div>
              </div>

              <div className="wrap-list-main-menu">
                <div
                  className="wrap-box-main-menu"
                  onClick={() => navigate("/profile")}
                >
                  <p>ข้อมูลส่วนบุคคล</p>
                  <div className="wrap-img-list-main-menu">
                    <img
                      className="icon-main-absolute icon-size"
                      src={ICONUSER}
                      alt="icon-user"
                    ></img>
                  </div>
                </div>
                <div
                  className="wrap-box-main-menu"
                  onClick={() => navigate("/list-document-teacher-approved")}
                >
                  <p>เอกสารคำร้องที่ลงนามแล้ว</p>
                  <div className="wrap-img-list-main-menu">
                    <img
                      className="icon-main-absolute icon-size"
                      src={ICONLIST}
                      alt="icon-open-folder"
                    ></img>
                  </div>
                </div>
                <div
                  className="wrap-box-main-menu"
                  onClick={() => navigate("/list-document-teacher")}
                >
                  <p>เอกสารคำร้องที่ยังไม่ได้ลงนาม</p>
                  <div className="wrap-img-list-main-menu">
                    <img
                      className="icon-main-absolute icon-size"
                      src={ICONPENCIL}
                      alt="icon-open-folder"
                    ></img>
                  </div>
                </div>
                {userDetail.Role === "OFFICER" ? (
                  <div
                    className="wrap-box-main-menu"
                    onClick={() => navigate("/dashboard")}
                  >
                    <p>รายงาน</p>
                    <div className="wrap-img-list-main-menu">
                      <img
                        className="icon-main-absolute icon-size"
                        src={ICONCHART}
                        alt="icon-chart"
                      ></img>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {userDetail.Role === "OFFICER" ? (
                  <div
                    className="wrap-box-main-menu"
                    onClick={() => navigate("/list-document")}
                  >
                    <p>ผู้ดูแลระบบ</p>
                    <div className="wrap-img-list-main-menu">
                      <img
                        className="icon-main-absolute icon-size"
                        src={ICONADMIN}
                        alt="icon-open-folder"
                      ></img>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            ""
          )
        ) : (
          <Spin />
        )}
        {userDetail && userDetail.Role === "STUDENT" ? (
          <div className="warning-msg">
            หมายเหตุ :
            ขอให้นิสิตปรับปรุงข้อมูลส่วนบุคคลให้เป็นปัจจุบันก่อนกรอกเอกสารคำร้อง
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

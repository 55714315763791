import "./style.scss";

import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
} from "antd";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";

import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

export default function RequesForm1(form) {
  const topicsKu1Reason = useSelector(
    (state) => state.masterData.masterData.ku1Reason
  );
  useEffect(() => {
    if (form.form.signatureImage) {
      form.setFieldsValue({
        signature: form.signatureImage,
      });
    }
  }, []);

  const adviserMasterData = useSelector(
    (state) => state.masterData.masterData.professors
  );
  const [isClearSignature, setIsClearSignature] = useState(false);

  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const sigCanvas = useRef({});
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
    setIsClearSignature(true);
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  const calSumCredit = () => {
    const creditSum = form.form
      .getFieldValue("form_subjects")
      .map((x) => x.form_credit)
      .reduce((a, b) => parseInt(a) + parseInt(b), 0);
    if (creditSum) {
      form.form.setFieldsValue({
        form_sumCredit: creditSum,
      });
    }
  };

  return (
    <div className="wrap-form wrap-form-1">
      <h2 className="header-text-style">รายวิชาที่ขอลงทะเบียนเรียน</h2>
      <div className="wrap-dynamic-responsive form-ku-1">
        <div style={{ width: 1100 }}>
          <Form.List name="form_subjects">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{
                      display: "flex",
                      marginBottom: 8,
                    }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "form_subjectId"]}
                      label={key === 0 ? "รหัสวิชา" : null}
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุ รหัสวิชา",
                        },
                        {
                          min: 8,
                          message: "รหัสวิชา 8 ตัวอักษร",
                        },
                        {
                          max: 8,
                          message: "รหัสวิชาไม่เกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input placeholder="รหัสวิชา" style={{ width: 100 }} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_typeofRegister"]}
                      label={key === 0 ? "ประเภทการลงทะเบียนเรียน" : null}
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุ ประเภทการลงทะเบียนเรียน",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: 200 }}
                        placeholder="เลือก"
                        optionFilterProp="children"
                      >
                        <Option value="Credit">Credit</Option>
                        <Option value="Audit">Audit</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_categories_lec"]}
                      label={key === 0 ? "หมู่เรียนบรรยาย" : null}
                      rules={[
                        {
                          max: 8,
                          message: "หมู่เรียนไม่ควรเกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 105 }}
                        placeholder="หมู่เรียนบรรยาย"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_categories_lab"]}
                      label={key === 0 ? "หมู่เรียนปฏิบัติการ" : null}
                      rules={[
                        {
                          max: 8,
                          message: "หมู่เรียนไม่ควรเกิน 8 ตัวอักษร",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 120 }}
                        placeholder="หมู่เรียนปฏิบัติการ"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_nameofSubjectEn"]}
                      label={
                        key === 0 ? "ชื่อวิชา (เขียนเป็นภาษาอังกฤษ)" : null
                      }
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุ ชื่อวิชา (เขียนเป็นภาษาอังกฤษ)",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: 250 }}
                        placeholder="ชื่อวิชา (เขียนเป็นภาษาอังกฤษ)"
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "form_credit"]}
                      label={key === 0 ? "หน่วยกิต" : null}
                      rules={[
                        {
                          required: true,
                          message: "กรุณาระบุ หน่วยกิต",
                        },
                      ]}
                    >
                      <InputNumber
                        onBlur={() => {
                          calSumCredit();
                        }}
                        style={{ width: 100 }}
                        maxLength={4}
                        placeholder="หน่วยกิต"
                      />
                    </Form.Item>

                    <div className="remove-element">
                      {key !== 0 ? (
                        <CloseCircleOutlined
                          onClick={() => {
                            remove(name);
                            calSumCredit();
                          }}
                        />
                      ) : null}
                    </div>
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    className="add-element"
                    onClick={() => add()}
                    block
                    icon={<PlusCircleOutlined />}
                  >
                    กดเพื่อเพิ่มแถวใหม่
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </div>
      <Row>
        <Form.Item
          name="form_semester"
          label="ภาคเรียน"
          style={{ paddingRight: "8px" }}
        >
          <Select
            style={{ width: 200 }}
            placeholder="เลือก"
            optionFilterProp="children"
          >
            <Option value="ภาคต้น">ภาคต้น</Option>
            <Option value="ภาคฤดูร้อน">ภาคฤดูร้อน</Option>
            <Option value="ภาคปลาย">ภาคปลาย</Option>
          </Select>
        </Form.Item>
        <Form.Item name="form_semesterYear" label="ปีการศึกษา">
          <Select
            style={{ width: 200 }}
            placeholder="เลือก"
            optionFilterProp="children"
          >
            <Option value="2566">2566</Option>
            <Option value="2567">2567</Option>
            <Option value="2568">2568</Option>
          </Select>
        </Form.Item>
      </Row>

      <Form.Item name="form_sumCredit" label="หน่วยกิตรวม">
        <Input placeholder="หน่วยกิตรวม" style={{ width: 120 }} readOnly />
      </Form.Item>

      <Row gutter={20}>
        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
            rules={[
              {
                message: "กรุณาลงนามกำกับเอกสาร",
                required: true,
              },
            ]}
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              {form.signatureImage && !isClearSignature ? (
                <div className="wrap-image-signature">
                  <img src={form.signatureImage} alt="signature" />
                </div>
              ) : null}
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export const getImageDimensions = async (base64) => {
  if (base64) {
    let img = new Image();
    img.src = base64;
    await img.decode();
    let width = img.width;
    let height = img.height;
    return {
      width: width,
      height: height,
    };
  } else {
    return {
      width: 0,
      height: 0,
    };
  }
};

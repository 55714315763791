import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ApiEndPoint from "../../shared/api/ApiEndPoint";
import ApiClient from "../../shared/api/ApiClient";

const initialState = {
  isLoading: false,
  previewData: null,
  error: null,
};

export const fetchPreviewData = createAsyncThunk(
  "request/preview",
  async (searchRequest, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(
        `${ApiEndPoint.PREVIEW}/${searchRequest}`
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const previewFormData = createSlice({
  name: "previewFormData",
  initialState,
  reducers: {
    setPreviewDataNull: (state) => {
      state.previewData = undefined;
    },
  },
  extraReducers: {
    [fetchPreviewData.pending]: (state) => {
      state.isLoading = true;
      state.previewData = null;
    },
    [fetchPreviewData.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.previewData = action.payload;
    },

    [fetchPreviewData.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default previewFormData.reducer;
export const { setPreviewDataNull } = previewFormData.actions;

import "./style.scss";

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Upload,
  message,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ApiClient from "../../shared/api/ApiClient";
import ICONUPLOADPROFILE from "../../assets/image/logo/logo-user-green.svg";
import { LeftOutlined } from "@ant-design/icons";
import { USER } from "../../shared/api/ApiEndPoint";
import { fetchMasterData } from "../Main/MainPageSlice";
import moment from "moment";
import { parseJwt } from "../../util/TokenUtill";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const STUDENT_ROLE = "STUDENT";

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

function EditProfilePage() {
  const [masterData, setMasterData] = useState();
  const [userDetail, setUserDetail] = useState();
  const [form] = Form.useForm();
  const dispact = useDispatch();
  const stateMasterData = useSelector((state) => state.masterData);
  const navigate = useNavigate();
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
  const [flxHideBackTomain, setflxHideBackTomain] = useState();
  const [imgBase64, setImgBase64] = useState("");
  const [department, setDepartment] = useState(null);

  // get master data
  // useEffect(() => {
  //     dispact(fetchMasterData())
  // }, [dispact]);

  useEffect(() => {
    if (stateMasterData?.masterData) {
      setMasterData(stateMasterData.masterData);
    }
  }, [stateMasterData]);

  useEffect(() => {
    let refreshMain = sessionStorage.getItem("refresh_main");
    if (refreshMain === "true") {
      window.location.reload();
      sessionStorage.setItem("refresh_main", "false");
    } else {
      dispact(fetchMasterData());
      getUserProfile();
      notification.warning({
        message: "คำเตือน",
        description: "โปรดกรอกข้อมูลให้ครบถ้วน",
      });
    }

    let flxHiden = sessionStorage.getItem("flx-edit-profile");
    if (flxHiden === "true") {
      setflxHideBackTomain(true);
    } else {
      setflxHideBackTomain(false);
    }
  }, []);

  async function getUserProfile() {
    try {
      let userDetail = parseJwt(sessionStorage.getItem("access_token"));
      let result = await ApiClient.get(
        `${process.env.REACT_APP_API_HOST}${USER}/${userDetail.user_id}`
      );
      result.data.DateOfBirth = result.data.DateOfBirth
        ? moment(result.data.DateOfBirth)
        : "";

      setImgBase64(result.data.ProfileImage);
      setUserDetail(result.data);
      setDepartment(result.data.Department);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function onSubmitForm(value) {
    let dob = form.getFieldValue("DateOfBirth");
    let objSave = value;
    if (userDetail?.Role === STUDENT_ROLE) {
      objSave.CurrentAddress.Id = userDetail.CurrentAddress.Id;
    }
    objSave.DateOfBirth = dob._d;
    objSave.AcademicYear = parseInt(value.AcademicYear);
    objSave.ProfileImage = imgBase64;
    if (value.Department !== "วิทยาศาสตร์เกษตร") {
      objSave.Major = "";
      objSave.MajorCode = "";
    }
    // call service update user
    setLoadingUpdateProfile(true);
    try {
      let userDetail = parseJwt(sessionStorage.getItem("access_token"));
      await ApiClient.patch(
        `${process.env.REACT_APP_API_HOST}${USER}/${userDetail.user_id}`,
        objSave
      );
      setLoadingUpdateProfile(false);
      sessionStorage.setItem("flx-edit-profile", "false");
      navigate("/profile");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "update profile service error !",
      });
      setLoadingUpdateProfile(false);
    }
  }

  async function onUploadImgProfile(file) {
    console.log("file", file);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }

    const isLt2M = file.size / 1024 / 1024 < 1;

    if (!isLt2M) {
      message.error("Image must smaller than 1MB!");
    }

    if (isJpgOrPng && isLt2M) {
      try {
        let base64 = await blobToBase64(file.originFileObj);
        setImgBase64(base64);
        console.log("base64", base64);
      } catch (error) {
        console.log("error", error);
      }
    }
  }
  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  return (
    <div className="wrap-edit-profile-page">
      <div className="wrap-content-edit-profile-page">
        <Row>
          <Col md={24}>
            <div className="wrap-header-edit-profile-page">
              {!flxHideBackTomain ? (
                <Button
                  icon={<LeftOutlined />}
                  onClick={() => navigate("/profile")}
                />
              ) : null}
              <div>
                <p className="top-txt-content">แก้ไขข้อมูลส่วนบุคคล</p>
                <div className="wrap-main-yellow-page"></div>
              </div>
            </div>
          </Col>
        </Row>
        {userDetail ? (
          <>
            <Form
              name="editProfileForm"
              className="wrap-edit-profile-form"
              form={form}
              onFinish={onSubmitForm}
              initialValues={userDetail}
              layout="vertical"
            >
              <Row className="wrap-section-upload-photo">
                <Col md={24}>
                  <Row align="middle">
                    <Upload
                      fileList={[]}
                      customRequest={dummyRequest}
                      onChange={(file) => onUploadImgProfile(file.file)}
                    >
                      <div className="wrap-backgroud-image">
                        <img
                          src={imgBase64 ? imgBase64 : ICONUPLOADPROFILE}
                          alt="upload-profile"
                          style={{
                            width: 75,
                            height: 75,
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        ></img>
                      </div>
                    </Upload>
                    <span className="txt-upload-image">อัปโหลดภาพโปรไฟล์</span>
                  </Row>
                </Col>
              </Row>
              {/* form section */}
              <div className="wrap-edit-profile-box">
                <Row gutter={16}>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label="คำนำหน้าชื่อ"
                      name="ThaiPrename"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                    >
                      <Select>
                        {stateMasterData?.masterData?.preName?.map((x, i) => {
                          return (
                            <Select.Option key={x.Name + i} value={x.Name}>
                              {" "}
                              {x.Name}{" "}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      name="FirstnameTh"
                      label="ชื่อ"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      name="LastnameTh"
                      label="ชื่อสกุล"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label="NAME"
                      name="FirstnameEng"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      label="FAMILY NAME"
                      name="LastnameEng"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col md={12} sm={12} xs={24}>
                    <Form.Item
                      name="Code"
                      label={
                        userDetail?.Role === "PROFESSOR"
                          ? "เลขประจำตัวอาจารย์"
                          : userDetail?.Role === "OFFICER"
                          ? "เลขประจำตัวเจ้าหน้าที่"
                          : "เลขประจำตัวนิสิต"
                      }
                    >
                      {userDetail?.Role === STUDENT_ROLE ? (
                        <Input disabled></Input>
                      ) : (
                        <Input></Input>
                      )}
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    <Form.Item
                      name="PersonalEmail"
                      label="Email"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} sm={12} xs={24}>
                    <Form.Item
                      name="Phonenumber"
                      label="โทรศัพท์"
                      rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                    >
                      <Input></Input>
                    </Form.Item>
                  </Col>

                  {userDetail?.Role === "STUDENT" ? (
                    <Col md={12} sm={12} xs={24}>
                      <Form.Item
                        name="DateOfBirth"
                        label=" วัน/เดือน/ปีเกิด"
                        rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                      >
                        <DatePicker format={"DD-MM-YYYY"} />
                      </Form.Item>
                    </Col>
                  ) : null}
                </Row>
                <Row gutter={16}>
                  {userDetail?.Role === "STUDENT" ? (
                    <Col md={12} sm={12} xs={24}>
                      <Form.Item
                        name="ReportToId"
                        label="อาจารย์ที่ปรึกษา"
                        rules={[
                          {
                            required: userDetail?.Role === STUDENT_ROLE,
                            message: "โปรดกรอกข้อมูล",
                          },
                        ]}
                      >
                        <Select>
                          {masterData?.professors.map((data) => {
                            return (
                              <Option key={data.Id} value={data.Id}>
                                {data.Name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : null}
                </Row>

                <Row gutter={16}>
                  <Col md={12} sm={12} xs={24}>
                    <Form.Item name="Faculty" label="คณะ">
                      <Input disabled></Input>
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    {userDetail?.Role === "STUDENT" ||
                    userDetail?.Role === "PROFESSOR" ? (
                      <Form.Item
                        label="สาขาวิชา"
                        name="Department"
                        rules={[{ required: true }]}
                      >
                        <Select
                          onChange={(e) => {
                            setDepartment(e);
                          }}
                        >
                          {masterData?.departments?.map((data) => {
                            return (
                              <Option key={data.Name} value={data.Name}>
                                {data.Name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col md={12} sm={12} xs={24}>
                    {(userDetail?.Role === "STUDENT" ||
                      userDetail?.Role === "PROFESSOR") &&
                    department === "วิทยาศาสตร์เกษตร" ? (
                      <Form.Item
                        label="แขนงวิชา"
                        name="Major"
                        rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                      >
                        <Select>
                          {masterData?.majors?.map((data, i) => {
                            return (
                              <Option key={data.Name + i} value={data.Name}>
                                {data.Name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    ) : null}
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    {userDetail?.Role === "STUDENT" &&
                    department === "วิทยาศาสตร์เกษตร" ? (
                      <Form.Item name="MajorCode" label="รหัสสาขาวิชา">
                        <Input></Input>
                      </Form.Item>
                    ) : null}
                  </Col>
                </Row>
                <Row gutter={16}>
                  {userDetail?.Role === "STUDENT" ? (
                    <Col md={12} sm={12} xs={24}>
                      <Form.Item
                        label="นิสิตชั้นปีที่"
                        name="AcademicYear"
                        rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                      >
                        <Select>
                          <Option key={1} value={1}>
                            {1}
                          </Option>
                          <Option key={2} value={2}>
                            {2}
                          </Option>
                          <Option key={3} value={3}>
                            {3}
                          </Option>
                          <Option key={4} value={4}>
                            {4}
                          </Option>
                          <Option key={5} value={5}>
                            {5}
                          </Option>
                          <Option key={6} value={6}>
                            {6}
                          </Option>
                          <Option key={7} value={7}>
                            {7}
                          </Option>
                          <Option key={8} value={8}>
                            {8}
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : null}
                  {userDetail?.Role === "STUDENT" ? (
                    <Col md={12} sm={12} xs={24}>
                      <Form.Item
                        name="Status"
                        label="สถานะการศึกษา"
                        rules={[{ required: true, message: "โปรดกรอกข้อมูล" }]}
                      >
                        <Select>
                          {masterData?.statuses?.map((data) => {
                            return (
                              <Option key={data.Name} value={data.Name}>
                                {data.Name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  ) : null}
                </Row>
              </div>
              {/* Change  */}

              {userDetail.Role === "STUDENT" ? (
                <>
                  <br></br>
                  <Row>
                    <Col md={24}>
                      <p className="warp-label-header">ที่อยู่ปัจจุบัน</p>
                    </Col>
                  </Row>
                  <br></br>

                  <div className="wrap-edit-profile-box">
                    <Row gutter={16}>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="บ้านเลขที่"
                          name={["CurrentAddress", "HomeNo"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="หมู่ที่"
                          name={["CurrentAddress", "VillageNo"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="ซอย"
                          name={["CurrentAddress", "Soi"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="ถนน"
                          name={["CurrentAddress", "Road"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>

                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="หมู่บ้าน"
                          name={["CurrentAddress", "VillageName"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="แขวง/ตำบล"
                          name={["CurrentAddress", "District"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="เขต/อำเภอ"
                          name={["CurrentAddress", "City"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="จังหวัด"
                          name={["CurrentAddress", "Province"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>

                      <Col md={12} sm={12} xs={24}>
                        <Form.Item
                          label="รหัสไปรษณีย์"
                          name={["CurrentAddress", "Zipcode"]}
                          rules={[
                            { required: true, message: "โปรดกรอกข้อมูล" },
                          ]}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : (
                <></>
              )}
              {userDetail.Role === "STUDENT" ? (
                <>
                  <br></br>
                  <Row>
                    <Col md={24} sm={12} xs={24}>
                      <p className="warp-label-header">ผู้ติดต่อกรณีฉุกเฉิน</p>
                    </Col>
                  </Row>
                  <br></br>
                  <div className="wrap-edit-profile-box">
                    <Row gutter={16}>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item name="FamilyContact" label="ชื่อ - ชื่อสกุล">
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={12} xs={24}>
                        <Form.Item name="FamilyPhone" label="โทรศัพท์">
                          <Input></Input>
                        </Form.Item>
                      </Col>

                      <Col md={12} sm={12} xs={24}>
                        <Form.Item name="FamilyRelation" label="ความสัมพันธ์">
                          <Input></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                  <br></br>

                  <Row>
                    <Col md={24}>
                      <p className="warp-label-header">โรคประจำตัว</p>
                    </Col>
                  </Row>
                  <br></br>

                  <div className="wrap-edit-profile-box">
                    <Row gutter={16}>
                      <Col md={24} sm={24} xs={24}>
                        <Form.Item
                          name="MedicalInformation"
                          label="ระบุรายละเอียด"
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </>
              ) : null}
              <Row className="wrap-button-section-form">
                <Button loading={loadingUpdateProfile} htmlType="submit">
                  บันทึกการแก้ไข
                </Button>
                {!flxHideBackTomain ? (
                  <Button
                    onClick={() => {
                      navigate("/main");
                    }}
                  >
                    ยกเลิก
                  </Button>
                ) : null}
              </Row>
            </Form>
          </>
        ) : (
          <Spin size="default" />
        )}
      </div>
    </div>
  );
}

export default EditProfilePage;

export const ENV = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  TEST: 'test'
}

export const HTTP_STATUS_CODE = {
  OK: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403, // session expire
};


export const ApproveCode = {
  self: "self",
  advisor: "advisor",
  chiefSyllabus: "chiefSyllabus",
  studyOfficer: "studyOfficer",
  officeAdmin: "officeAdmin",
  dean: "dean",
  print: "print",
};
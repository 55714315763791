import "./style.scss";

import * as XLSX from "xlsx-js-style";

import { ArcElement, Chart, Legend, PieController, Tooltip } from "chart.js";
import { Button, Col, DatePicker, Form, Row, Select, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";

import ApiClient from "../../shared/api/ApiClient";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { LeftOutlined } from "@ant-design/icons";
import { Pie } from "react-chartjs-2";
import dayjs from "dayjs";
import localeTh from "dayjs/locale/th";
import { useNavigate } from "react-router-dom";

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const backgroudList = [
  "#DE6786",
  "#E6A551",
  "#EFD266",
  "#CACBCF",
  "#7FBFBF",
  "#6D9BE6",
  "#9353F9",
  "#9FE2BF",
  "#CCCCFF",
  "#FF7F50",
  "#DFFF00",
];

export default function DashboardPage() {
  const navigate = useNavigate();
  const [report, setReport] = useState(null);
  const [reportCode, setReportCode] = useState();
  const [reportDtlList, setReportDtlList] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const chartRef = useRef(null);
  const [dataGraps, setDataGraps] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);
  useEffect(() => {
    getReport();
  }, []);

  async function getReport() {
    let result = await (
      await ApiClient.get(`${process.env.REACT_APP_API_HOST}/report`)
    ).data;
    setReport(result);
  }

  async function getReprotDetail() {
    if (startDate && !endDate) {
      notification.warning({
        message: "คำเตือน",
        description: "โปรดกรอกช่วงเวลาสิ้นสุด",
      });
      return;
    }

    if (!startDate && endDate) {
      notification.warning({
        message: "คำเตือน",
        description: "โปรดกรอกช่วงเวลาเริ่มต้น",
      });
      return;
    }

    if (startDate > endDate) {
      notification.warning({
        message: "คำเตือน",
        description: "โปรดกรอกช่วงเวลาให้ถูกต้อง",
      });
      return;
    }

    //console.log('reportCode :>> ', reportCode);
    let result = await (
      await ApiClient.get(
        `${process.env.REACT_APP_API_HOST}/report/${reportCode}${startDate && endDate
          ? "?start=" +
          dayjs(startDate).format("YYYY-MM-DDT00:00:01[Z]") +
          "&end=" +
          dayjs(endDate).format("YYYY-MM-DDT23:59:59[Z]")
          : ""
        }`
      )
    ).data;
    //console.log('result :>> ', result);
    setReportDtlList(result);

    if (result) {
      let resultDataGrape = [];

      for (const datas of result?.graphs) {
        let labels = [];
        let modalDataGrapes = {
          //labels: labels,
          datasets: [
            {
              label: "",
              data: datas.items?.map((x) => x.count),
              backgroundColor: backgroudList,
              hoverOffset: 4,
            },
          ],
        };
        resultDataGrape.push(modalDataGrapes);
        setDataGraps(resultDataGrape);
      }
    }
  }

  function exportExcel() {
    let data = [];
    let index = 0;
    // { v: "Courier: 24", t: "s", s: { font: { name: "Courier", sz: 24 } } }
    data.push([
      {
        v: `${report.find((element) => element.Code === reportCode)?.Name}`,
        t: "s",
        s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
      },
    ]);
    data.push([
      {
        v: `${startDate && endDate
            ? `ตั้งแต่ ${dayjs(startDate)
              .locale(localeTh)
              .format("DD/MM/YYYY")} ถึง  ${dayjs(endDate)
                .locale(localeTh)
                .format("DD/MM/YYYY")}`
            : "ช่วงเวลา ทั้งหมด"
          }`,
        t: "s",
        s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
      },
    ]);

    let graphIndex = 0;
    reportDtlList?.graphs?.forEach((element) => {
      data.push([
        {
          v: `${getTitle(graphIndex)}`,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
        },
        {
          v: `จำนวน (ฉบับ)`,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
        },
        {
          v: `ร้อยละ`,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
        },
      ]);
      let sumReport = element.items?.reduce(
        (previousValue, currentValue) => previousValue + currentValue.count,
        0
      );
      element.items.forEach((x) => {
        data.push([
          {
            v: `${x.name}`,
            t: "s",
            s: { font: { sz: 12 }, alignment: { horizontal: "left" } },
          },
          {
            v: `${x.count}`,
            t: "s",
            s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
          },
          {
            v: `${((x.count / sumReport) * 100).toFixed(2)}`,
            t: "s",
            s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
          },
        ]);
      });

      data.push([
        {
          v: `รวม`,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "left" } },
        },
        {
          v: `${sumReport}`,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
        },
        {
          v: `100.00`,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "right" } },
        },
      ]);

      data.push([
        {
          v: ``,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
        },
        {
          v: ``,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
        },
        {
          v: ``,
          t: "s",
          s: { font: { sz: 12 }, alignment: { horizontal: "center" } },
        },
      ]);
      graphIndex++;
    });

    downloadExcel(data);
  }
function getTitle(i){
  return  i === 0 && reportCode === "MoveByTopic"
  ? "ย้ายสาขาวิชา/แขนงวิชา"
  :i === 0 && reportCode === "BreakByTopic"? 'ภาคการศึกษา'
  :i === 0 && reportCode === "OffByTopic"? 'ประเภทการลา'
  :i === 0 && reportCode === "LeaveByMajor"? 'สาขาวิชา/แขนงวิชา'
  :i === 2 && reportCode === "OffByTopic"? 'สาขาวิชา/แขนงวิชา'
  :i === 2 && reportCode === "BreakByTopic"? 'สาขาวิชา/แขนงวิชา'
  : i === 0
    ? "ชื่อคำร้อง"
    : i === 1
      ? "ชั้นปีที่"
      : i === 2 || i === 3
        ? "สาขาวิชา/แขนงวิชา"
        : i === 4 || i === 5
          ? "สาขาวิชา/แขนงวิชา"
          : ""
}
  /**
   *
   * @param {Array} data
   * @param {*} allReson
   */
  function downloadExcel(data, allReson) {
    // STEP 1: Create a new Workbook
    const wb = XLSX.utils.book_new();

    // STEP 3: Create Worksheet, add data, set cols widths
    const ws = XLSX.utils.aoa_to_sheet(data);
    ws["!cols"] = [{ width: 30 }, { width: 20 }, { width: 20 }];
    ws["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 5 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 5 } },
    ];
    XLSX.utils.book_append_sheet(wb, ws, "report");

    //STEP 3.1: create another sheet, reason
    if (reportDtlList.hasOwnProperty("allReason")) {
      let dataAllReason = [];
      dataAllReason.push({
        col1: "เหตุผล",
      });
      for (const x of reportDtlList.allReason) {
        dataAllReason.push({
          col1: x,
        });
      }

      const worksheet2 = XLSX.utils.json_to_sheet(dataAllReason, {
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, worksheet2, "reason");
    }

    // STEP 4: Write Excel file to browser
    XLSX.writeFile(wb, "report.xlsx");
  }

  function downloadGraph() {
    const link = document.createElement("a");
    link.download = "chart.jpeg";
    link.href = chartRef.current.toBase64Image();
    link.click();
  }

  return (
    <div className="warp-dashborad-page">
      <div className="navigate-top">
        <div className="left">
          <Button icon={<LeftOutlined />} onClick={() => navigate("/main")} />
          <h1 className="top-txt-content">รายงาน</h1>
        </div>
        <div className="right">
          <Button onClick={() => navigate("/main")}>ไปเมนูหลัก</Button>
        </div>
      </div>
      <div className="wrap-dashboard-search-section">
        <Form name="form-search-dashboard">
          <Row gutter={[16, 16]}>
            <Col md={8}>
              <p className="wrap-dashborad-label-txt">เลือกประเภทข้อมูล</p>
              <Select
                placeholder="โปรดเลือกประเภทข้อมูล"
                onChange={(x) => setReportCode(x)}
              >
                {report?.map((x, i) => {
                  return (
                    <Select.Option key={x.Code} value={x.Code}>
                      {" "}
                      {x.Name}{" "}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
            <Col md={4}>
              <p className="wrap-dashborad-label-txt">ตั้งแต่</p>
              <DatePicker
                format={"DD-MM-YYYY"}
                onChange={(x) => setStartDate(x?._d)}
              />
            </Col>
            <Col md={4}>
              <p className="wrap-dashborad-label-txt">ถึง</p>
              <DatePicker
                format={"DD-MM-YYYY"}
                onChange={(x) => setEndDate(x?._d)}
              />
            </Col>
            <Col md={8}>
              <Button
                className="wrap-btn-search-dashboard"
                onClick={() => getReprotDetail()}
              >
                แสดงผล
              </Button>
              {reportDtlList ? (
                <Button
                  className="wrap-btn-search-dashboard"
                  onClick={() => exportExcel()}
                >
                  Export Report
                </Button>
              ) : (
                ""
              )}
              {/* {reportDtlList ? <Button className='wrap-btn-search-dashboard' onClick={() => downloadGraph()}>Export Graph</Button> : ""} */}
            </Col>
          </Row>
        </Form>
      </div>
      <div className="wrap-hr-search-section"></div>

      {reportDtlList
        ? reportDtlList?.graphs.map((y, i) => {
          return reportDtlList?.graphs[i].items.length > 0 ? (
            <div style={{ marginBottom: 10 }}>
              <div className="wrap-chart-dashboard-section">
                <div className="wrap-chart-dashboard">
                  <Pie
                    data={dataGraps[i]}
                    options={{
                      plugins: {
                        tooltip: {
                          enabled: false,
                        },
                        datalabels: {
                          formatter: (value, context) => {
                            let sum = 0;
                            let dataArr = context.chart.data.datasets[0].data;
                            dataArr.map((data) => {
                              sum += data;
                            });
                            let percentage =
                              ((value * 100) / sum).toFixed(2) + "%";
                            return percentage;
                          },
                          color: "#000000",
                          font: {
                            weight: "bold",
                            size: "10px",
                          },
                        },
                      },
                    }}
                    ref={chartRef}
                  ></Pie>
                </div>
                <div className="wrap-chart-dashboard-detail">
                  {reportDtlList?.graphs[i].items?.map((x, ii) => {
                    return (
                      <div
                        key={x.name + ii}
                        className="wrap-list-chart-detail"
                      >
                        <div
                          className="chart-box-color"
                          style={{ backgroundColor: backgroudList[ii] }}
                        ></div>
                        <span> {x.name} </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="wrap-dashbord-chart-detail">
                <table className="table-report">
                  <thead>
                    <th>
                      {getTitle(i)}
                    </th>
                    <th>จำนวน (ฉบับ)</th>
                  </thead>
                  <tbody>
                    {reportDtlList?.graphs[i].items
                      ?.sort(function (a, b) {
                        if (a?.name < b?.name) {
                          return -1;
                        }
                        if (a?.name > b?.name) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((x) => (
                        <tr>
                          <td>{x.name}</td>
                          <td> {x.count}</td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <td>รวม</td>
                    <td>
                      {reportDtlList?.graphs[i].items?.reduce(
                        (previousValue, currentValue) =>
                          previousValue + currentValue.count,
                        0
                      )}
                    </td>
                  </tfoot>
                </table>
              </div>
            </div>
          ):<></>
        })
        : ""}
    </div>
  );
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ApiEndPoint from "../../shared/api/ApiEndPoint";
import ApiClient from "../../shared/api/ApiClient";

const initialState = {
  isLoading: false,
  requestList: null,
  error: null,
};

export const fetchRequestList = createAsyncThunk(
  "request/list",
  async (searchRequest, { rejectWithValue }) => {
    try {
      const response = await ApiClient.get(
        ApiEndPoint.REQUESTLIST,
        searchRequest
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const requestList = createSlice({
  name: "requestList",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchRequestList.pending]: (state) => {
      state.isLoading = true;
      state.requestList = null;
    },
    [fetchRequestList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.requestList = action.payload?.Items;
    },

    [fetchRequestList.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default requestList.reducer;

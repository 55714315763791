export const validateUserProfile = (userData) => {
  let keyCheckStudent = [
    "Department",
    "Status",
    "Phonenumber",
    "DateOfBirth",
    "AcademicYear",
    "ReportTo",
  ];
  let keyCheckProfessor = ["Department"];
  let keyCheckOfficer = ["Phonenumber", "DateOfBirth"];
  let currentAddressCheck = [
    "City",
    "HomeNo",
    "Province",
    "District",
    "VillageNo",
    "Zipcode",
  ];
  let checked = true;
  try {
    if (userData) {
      if (userData.Role === "STUDENT") {
        keyCheckStudent.forEach((x) => {
          if (!userData[x] || userData[x] === "") {
            checked = false;
          }
        });
      } else if (userData.Role === "PROFESSOR") {
        keyCheckProfessor.forEach((x) => {
          if (!userData[x] || userData[x] === "") {
            console.log(x, "====> false");
            checked = false;
          }
        });
      } else if (userData.Role === "OFFICER") {
        keyCheckOfficer.forEach((x) => {
          if (!userData[x] || userData[x] === "") {
            console.log(x, "====> false");
            checked = false;
          }
        });
      } else {
        checked = false;
      }

      let userCurrnetAddress = userData.CurrentAddress;
      currentAddressCheck.forEach((x) => {
        if (!userCurrnetAddress[x] || userCurrnetAddress[x] === "") {
          console.log(x, "====> false");
          checked = false;
        }
      });
    }
    return checked;
  } catch (error) {
    console.log("error", error);
    return "Service Error";
  }
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as ApiEndPoint from "../../shared/api/ApiEndPoint";
import ApiClient from "../../shared/api/ApiClient";

const initialState = {
  isLoading: false,
  isCompleted: null,
  error: null,
};

export const fetchDraftForm = createAsyncThunk(
  "request/draft",
  async (dataForm, { rejectWithValue }) => {
    try {
      const response = await ApiClient.patch(
        `${ApiEndPoint.DRAFT}/${dataForm.id}`,
        dataForm.formData
      );
      return response.data;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.message);
    }
  }
);

export const draftForm = createSlice({
  name: "draftForm",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDraftForm.pending]: (state) => {
      state.isLoading = true;
      state.isCompleted = null;
    },
    [fetchDraftForm.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isCompleted = action.payload;
    },
    [fetchDraftForm.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    },
  },
});

export default draftForm.reducer;

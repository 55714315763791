import { Dropdown, Menu } from 'antd';
import React, { useEffect } from 'react'
import './style.scss'
import { UserOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import {clearUserDetail} from "../Profile/ProfilePageSlice"
import { useDispatch } from 'react-redux';
export default function HeaderApp() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: (
                        <span onClick={() => navigate('/profile')}>
                            ข้อมูลส่วนบุคคล&nbsp;&nbsp;&nbsp;
                        </span>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <span onClick={() => {
                            window.open('https://sso.ku.ac.th/nidp/app/logout', '_blank');
                            navigate('/login')
                            sessionStorage.clear()
                            dispatch(clearUserDetail())
                        }}>
                            ออกจากระบบ
                        </span>
                    ),
                }
            ]}
        />
    );
    const location = useLocation();
    return (

        <div className='wrap-header-app-component' >
            {location.pathname !== "/login" ?
                <Dropdown overlay={menu}>
                    <div className='wrap-bg-img-icon-header'>

                        <UserOutlined style={{ fontSize: '20px', color: "#E9F2F2" }} />
                    </div>

                    {/* <span style={{width:120}}>Header app</span> */}
                </Dropdown>
                : ""}
        </div>
    )
}
import "./style.scss";

import { Button, Col, Form, Input, Radio, Row, Select, Upload } from "antd";
import React, { useRef, useState } from "react";
import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";

const { TextArea } = Input;
const { Option } = Select;

export default function RequesForm4(form) {
  const [requestType, setRequestType] = React.useState(null);
  const [isClearSignature, setIsClearSignature] = React.useState(false);

  const oldDepartment = useSelector(
    (state) => state.userDetail.userDetail.Department
  );
  const oldMajor = useSelector((state) => state.userDetail.userDetail.Major);
  const departments = useSelector(
    (state) => state.masterData.masterData.departments
  );
  const majors = useSelector((state) => state.masterData.masterData.majors);

  const [filename, setFilename] = useState(null);
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  React.useEffect(() => {
    if (oldDepartment) {
      form.form.setFieldsValue({
        form_old_department: oldDepartment,
        form_topic: form.form.getFieldValue("form_topic"),
      });
      setRequestType(form.form.getFieldValue("form_topic"));
    }
    if (oldMajor) {
      form.form.setFieldsValue({
        form_old_major: oldMajor,
        form_topic: form.form.getFieldValue("form_topic"),
      });
      setRequestType(form.form.getFieldValue("form_topic"));
    }
  }, []);

  const sigCanvas = useRef({});
  const clear = () => {
    sigCanvas.current.clear();
    form.form.setFieldsValue({
      signature: undefined,
    });
    setIsClearSignature(true);
  };
  const save = () => {
    form.form.setFieldsValue({
      signature: sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"),
    });
  };
  return (
    <div className="wrap-form wrap-form-1">
      <Row gutter={20}>
        <Col md={24} sm={24} xs={24}>
          <Row gutter={20}>
            <Col md={12} sm={24} xs={24}>
              <Form.Item
                className="wrap-form-topic-radio"
                name="form_topic"
                label="เรื่อง"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุ เรื่อง",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setRequestType(e.target.value);
                  }}
                >
                  <Radio value={1}>ขอย้ายสาขาวิชา</Radio>
                  <Radio value={2}>ขอย้ายแขนงวิชา</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          {requestType === 2 ? (
            <>
              <Row gutter={20}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="form_old_major"
                    label="จากสาขาแขนงวิชาเดิม"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุ จากสาขาวิชาเดิม",
                      },
                    ]}
                  >
                    {oldMajor ? <Input readOnly /> : ""}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="form_new_major"
                    label="มีความประสงค์ขอย้ายไปเรียนแขนงวิชาใหม่"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุสาขาวิชา",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="เลือก"
                    >
                      {majors
                        ? majors.map((x, i) => (
                            <Option
                              key={i}
                              value={x.Name}
                              disabled={x.Name === oldMajor}
                            >
                              {x.name}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : requestType === 1 ? (
            <>
              <Row gutter={20}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="form_old_department"
                    label="จากสาขาวิชาเดิม"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุหลักสาขาวิชาเดิม",
                      },
                    ]}
                  >
                    {oldDepartment ? <Input readOnly /> : ""}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    name="form_new_department"
                    label="มีความประสงค์ขอย้ายไปเรียนสาขาวิชาใหม่"
                    rules={[
                      {
                        required: true,
                        message: "กรุณาระบุหลักสูตรใหม่",
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      placeholder="เลือก"
                    >
                      {departments
                        ? departments.map((x, i) => (
                            <Option
                              key={i}
                              value={x.Name}
                              disabled={x.Name === oldDepartment}
                            >
                              {x.name}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Col>
        <Col md={12} sm={24} xs={24}>
          <Form.Item
            name="form_reason"
            label="เนื่องจาก (ระบุเหตุผล)"
            rules={[
              {
                required: true,
                message: "กรุณาระบุ หน่วยกิตรวม",
              },
              {
                max: 1000,
                message: "ตัวอักษรไม่ควรเกิน 1000 ตัวอักษร",
              },
            ]}
          >
            <TextArea
              showCount
              maxLength={300}
              style={{ height: 180 }}
              placeholder="เนื่องจาก (ระบุเหตุผล)"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col md={12} sm={24} xs={24}>
          <div className="wrap-from-7-upload-section upload-form-1">
            <p>
              <span class="custom-required">* </span>
              เอกสารแนบ : Transcript ผลการเรียน
            </p>
            <div className="wrap-upload-component">
              <div className="file-name-section">{filename}</div>
              <div className="upload-btn">
                <Form.Item
                  name="form_atth_doc"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาแนบไฟล์",
                    },
                  ]}
                >
                  <Upload
                    accept="image/png, image/jpeg, .pdf"
                    onChange={(e) => {
                      setFilename(e.file.name);
                    }}
                    maxCount={1}
                    customRequest={dummyRequest}
                  >
                    <Button icon={<CloudUploadOutlined />} />
                  </Upload>
                </Form.Item>
              </div>
            </div>
            <span>นามสกุลไฟล์ที่รองรับ pdf. png. jpeg.</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Item
            className="form-ele-sign"
            name="signature"
            label="กรุณาลงนามกำกับเอกสาร"
            rules={[
              {
                message: "กรุณาระบุ กรุณาลงนามกำกับเอกสาร",
                required: true,
              },
            ]}
          >
            <div className="wrap-canvas">
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: "335px",
                  height: "150px",
                  className: "sigCanvas",
                }}
                onEnd={() => {
                  save();
                }}
              />
              {form.signatureImage && !isClearSignature ? (
                <div className="wrap-image-signature">
                  <img src={form.signatureImage} alt="signature" />
                </div>
              ) : null}
              <Button onClick={clear}>Clear</Button>
            </div>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
